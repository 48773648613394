import React, {useState, useEffect} from 'react';
import posed from 'react-pose';
import {useDispatch, useSelector} from "react-redux";
import {asyncNotify} from "./reducers/loginActions";

const Row = posed.div({
    visible: {
        opacity: 1,
        height: 'inherit',
        transition: {duration: 300}
    },
    hidden: {
        opacity: 0,
        height: 0,
        transition: {duration: 300}
    }
});

let t = null;

const Notification = () => {

    const [message, messageColor] = useSelector(s => [s.message, s.messageColor]);
    const [visible, setVisible] = useState(message.length > 0);
    const dispatch = useDispatch();

    useEffect(() => {

        if (message.length > 0) {
            setVisible(true);

            t = setTimeout(() => {
                setVisible(false);
                setTimeout(() => dispatch(asyncNotify('')), 3000)
            }, 3000);
        }

        return () => clearTimeout(t);
    }, [message, dispatch]);


    return (
        <Row
            pose={visible ? 'visible' : 'hidden'}
            style={{padding: visible ? '1rem' : 0, margin:'0 -15px'}}
            className={`bg-${messageColor} text-white`}
        >
                {message}
        </Row>

    );
};

export default Notification;