import React from 'react';
import NumberFormat from 'react-number-format';


const Nf = ({number, ...rest}) => {
    return (
        <NumberFormat displayType="text"
                      value={number}
                      className={"noWrap"}
                      thousandSeparator=" "
                      decimalSeparator='.'
                      fixedDecimalScale={true}

                      decimalScale={2}
                      {...rest}
        />
    );
};

export default Nf;