import React, {useState} from 'react';
import {Alert, Badge, Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Row} from "reactstrap";
import {Icon} from "react-icons-kit";
import {save} from "react-icons-kit/feather";
import api from "./api";
import {useDispatch} from "react-redux";
import {asyncNotify} from "./reducers/loginActions";

const Synonyms = ({synonyms, ProdNo}) => {

    const [localTags, setLocalTags] = useState([]);
    const [i, si] = useState('');
    const [saving, setSaving] = useState(false);
    const dispatch = useDispatch();


    const handleTagSave = () => {
        setSaving(true);

        api.productTags(ProdNo, localTags).then(() => {
            setSaving(-1);
            setLocalTags([]);
            si('');
        });

    };

    return (
        <FormGroup row className="synonymFormRow">
            <Col md={12}>
                <span>Synonymer:</span>
            </Col>
            <Col md={12}>
                <div className="synonymList">   
                    {synonyms.map(tag => (
                        <Badge color="primary" key={tag}>{tag}</Badge>
                    ))}
                    {localTags.map(tag => (
                        <Badge key={tag} color="success" onClick={e => setLocalTags(localTags.filter(t => t !== tag))}>
                            {tag}
                        </Badge>
                    ))}
                </div>
            </Col>
            {saving === -1 &&
            <Row>
                <Col md={12}>
                    <Alert color="success">
                        Synonymer er sendt. Det kan ta noe tid før synonymlisten er oppdatert.
                    </Alert>
                </Col>
            </Row>
            }
            {saving !== -1 && <Col className="orderLineAction">
                <Row>
                    <Col md={8}>
                        <InputGroup size="sm">
                            <Input value={i} disabled={saving} onChange={e => si(e.target.value)}/>
                            <InputGroupAddon addonType="append">
                                <Button disabled={saving} onClick={e => {
                                    if (synonyms.findIndex(s => i === s) === -1) {
                                        setLocalTags([...localTags, i]);
                                        si('');
                                    } else {
                                        dispatch(asyncNotify('Denne synonymen ekisterer', 'warning'));
                                    }
                                }}>Legg til synonym</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    <Col md={4} className=" text-right">
                        <Button color="success" disabled={saving || localTags.length < 1} onClick={handleTagSave}>
                            <Icon icon={save}/> Send inn synonymer
                        </Button>
                    </Col>
                </Row>
            </Col>}
        </FormGroup>
    );
};

export default Synonyms;