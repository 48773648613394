import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, ButtonGroup, Col} from "reactstrap";
import {LabelsDropdown} from "../Toolbox";
import {actions, sideViews} from "../reducers/configureStore";
import {Icon} from "react-icons-kit";
import {shoppingCart, chevronLeft} from "react-icons-kit/feather";
import {asyncOrderLines} from "../reducers/loginActions";

export const ToolboxWishList = () => {
    const action = useSelector(state => state.action);
    const dispatch = useDispatch();

    const [selection, uid, oid] = useSelector(s => [s.wishlistSelection, s.uid, s.orderId]);
    const disabled = Object.keys(selection).length === 0;

    const addToOrder = () => {

        dispatch({type: 'ADD_HIGHLIGHTED', products: Object.keys(selection)});

        const lines = Object.values(selection).map(line => ({
            produkt_nr: line.identifier,
            amount: line.antall
        }));

        dispatch(asyncOrderLines(oid, uid, lines));
    };

    return (<Col md={7} sm={12} className="toolBoxLeft">
            <ButtonGroup>

                <Button color="info"
                        onClick={e => dispatch({type: 'SET_PANE', pane: sideViews.ORDER})}>
                    <Icon icon={chevronLeft}/>
                </Button>

                <LabelsDropdown disabled={disabled} selection={selection}/>

                <Button disabled={disabled || action === actions.ADD_TO_ORDER}
                        color="info"
                        onClick={addToOrder}>
                    <Icon icon={shoppingCart}/> Legg til
                </Button>
            </ButtonGroup>
        </Col>
    );

};
