import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';

import {configuredStore} from "./reducers/configureStore";
import {Provider} from "react-redux";
import Routes from "./Routes";


Sentry.init({dsn: "https://e01adb83f3b3409c9a52f77d7bb64584@sentry.io/1769017"});

ReactDOM.render(
    <Provider store={configuredStore}>
        <Routes/>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
