import React from 'react';
import {useSelector} from "react-redux";
import {Col} from "reactstrap";
import {WishListLine} from "./WishListLine";

export function WishListPane() {
    const list = useSelector(s => s.wishlist);
    const order = useSelector(s => s.order);

    return (
        <Col className="wish-list-pane" md={7}>
            <div className="ListHeader">
                <h4>Salgsliste {list.name}</h4>
            </div>
            <ul>
                {!!list.lines && list.lines.map(line => <WishListLine
                    key={line.id}
                    line={line}
                    order={order}
                />)}

            </ul>
        </Col>
    );
}


