import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {Badge, Button, Col, Row} from "reactstrap";
import {Icon} from "react-icons-kit";
import {OrderInfo} from "./OrderInfo";

import {chevronDown, chevronUp, shoppingCart} from "react-icons-kit/feather";

import {actions} from "./reducers/configureStore";
import {asyncAddToWishList, asyncOrderLines} from "./reducers/loginActions";
import {openInfoPane, setReduxQuery} from "./reducers/selectors";
import {warehouses} from "./api/warehouses";
import Nf from "./NF";
import {useParams} from "./hooks/routerHooks";
import {fetchProduct} from "./hooks/useInfo";
import {useStock} from "./hooks/useStock";

export const SearchLine = ({product}) => {

    const [showInfo, setShowInfo] = useState(false);

    const [selection, orderId, uid] =
              useSelector(state => [state.searchSelection, state.orderId, state.uid]);

/*
    if(product.stock) {
        product.stock['Bergen'] =
            parseInt(product.stock['Bergen'] || 0, 10) +
            parseInt(product.stock['Fyllingsdalen'] || 0, 10);
    }

    const defaultWarehouse = (warehouses[stock] && warehouses[stock][0]) ? warehouses[stock][0] : 'Bergen';
    const stockAmount = product.stock[defaultWarehouse];

*/

    const stock = Object.keys(warehouses).map(k => {
        const n = k === '3' ? 'Fyllingsdalen' : warehouses[k][0];
        return {warehouse: k, amount: parseInt(product.stock[n] || 0, 10)};
    });

    const {defaultWarehouse, defaultStock} = useStock({stock});

    const selected = Object.keys(selection).indexOf(product.produkt_nr) !== -1;
    const action = useSelector(state => state.action);
    const [price, setPrice] = useState(null);

    const {lid} = useParams();

    const dispatch = useDispatch();

    const searchReplacement = rep => dispatch(setReduxQuery(rep));

    function toggle() {
        if (selected) {
            dispatch({type: 'REMOVE_FROM_SEARCH_SELECTION', line: product});

        } else {
            dispatch({type: 'SET_TO_SEARCH_SELECTION', line: product});
        }
    }

    const isInfoOpened = useSelector(s => s.opened.search === product.produkt_nr);
    const replacement = product.RepProd && product.RepProd.trim();
    const productToAdd = replacement || product.produkt_nr;

    useEffect(() => {
        if (!isInfoOpened) {
            setShowInfo(false);
        }
    }, [isInfoOpened]);

    const handlePaneState = () => {
        showInfo || dispatch(openInfoPane(product.produkt_nr, 'search'));
        setShowInfo(!showInfo);
    };

    const useHandlePriceFetch = () => {
        fetchProduct(product.produkt_nr, uid)
            .then(info => info.pricing && setPrice(info.pricing.price));
    };

    const addFunction = () => {

        if (lid) {
            dispatch(asyncAddToWishList(uid, lid, [{product: productToAdd, amount: 1}]))
                .then(() => dispatch({type: 'SET_WISHLIST_DIRTY', wishlistDirty: true}));
        } else {
            dispatch(asyncOrderLines(orderId, uid, [{
                produkt_nr: productToAdd,
                amount: parseFloat(product.produkt_felt_tekst_4) || 1
            }])).then(() => dispatch(setReduxQuery('')));
        }

        dispatch({type: 'ADD_HIGHLIGHTED', products: [productToAdd]});
    };

    return (

        <li className={selected ? `selected` : `unseleced`}>
            <Row className="no-gutters">
                <Col md={1} className="searchItem expand">
                    <Button color="transparent">
                        <Icon size={25} icon={showInfo ? chevronUp : chevronDown} onClick={handlePaneState}/></Button>
                </Col>
                <Col md={6} className="searchItem info" onClick={toggle}>
                    <strong>{product.produkt_navn}</strong><br/>
                    {product.produkt_nr}
                    {replacement.length > 0 && (
                        <span className="replacement text-danger">
                            {' '}Varen er erstattet av
                            <Button style={{fontSize: '0.8rem'}} color="link" size="sm"
                                    onClick={e => searchReplacement(replacement)}>{replacement}</Button>
                        </span>
                    )}

                </Col>
                <Col md={3} className="searchItem">
                    <div onClick={useHandlePriceFetch}>
                        <strong>
                            <Nf number={product.produkt_pris_ordinar}/>
                            {price > 0 && (
                                <Badge pill color="success" style={{fontSize: '0.8rem'}}><Nf number={price}/></Badge>
                            )}
                        </strong><br/>
                        {replacement.length > 0 || <span className="stock">{defaultWarehouse}: {defaultStock} </span>}
                    </div>
                </Col>
                <Col md={2} className="text-right searchItem add">
                    <Button disabled={action === actions.ADD_TO_ORDER}
                            id="caret" color="info" onClick={addFunction}>
                        <Icon icon={shoppingCart}/>
                    </Button>
                </Col>
            </Row>
            {showInfo && <OrderInfo replacement={replacement.length > 0}
                                    name={product.produkt_navn}
                                    ProdNo={product.produkt_nr}/>}
        </li>
    )
};