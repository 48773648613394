import {useSelector} from "react-redux";
import {warehouses} from "../api/warehouses";

export const useStock = ({stock}) => {

    const stockId = String(useSelector(state => parseInt(state.user && state.user.DATA_NUM_9, 10) || '1'));

    const defaultWarehouse = warehouses[stockId][0];

    const stockArray = Object.keys(warehouses).reduce((acc, x) => {

        const name = warehouses[x][0];

        !acc[name] && (acc[name] = 0);

        acc[name] += stock.reduce((amount, wh) => {
            const a = wh['warehouse'] === String(x) ? parseInt(wh['amount'], 10) : 0;
            return amount + a;
        }, 0);

        return acc;

    }, {});

    const defaultStock = stockArray[defaultWarehouse];

    return {stockId, defaultWarehouse, defaultStock, stockArray}
};
