import React from 'react';
import {Col, FormGroup, ListGroup, ListGroupItem} from "reactstrap";
import {LabelsDropdown, WishlistsDropdown} from "./Toolbox";
import {useSelector} from "react-redux";
import {relPath, useInfo} from "./hooks/useInfo";
import Nf from "./NF";

import {Icon} from "react-icons-kit";
import {book, folder} from "react-icons-kit/feather";
import {sideViews} from './reducers/configureStore';
import ShowMore from "./ShowMore";
import Synonyms from "./Synonyms";
import {useStock} from "./hooks/useStock";

const StockLines = ({stock}) => {

    const {stockArray} = useStock({stock});

    return <ul>{Object.keys(stockArray).map(k => <li key={k}>{k}: {stockArray[k]}</li>)}</ul>
};

export const OrderInfo = ({ProdNo, name, hideWishListButton = false, replacement = false}) => {
    const user = useSelector(s => s.uid);
    const pane = useSelector(s => s.pane);
    const {info, images, stock, pricing, files, tags} = useInfo(ProdNo, user);
    const fileTypes = {};

    files.map(file => {
        const type = (file.rel_path.split('/')[3] || '').toUpperCase();
        fileTypes[type] ? fileTypes[type].push(file) : fileTypes[type] = [file];

        return false;
    });

    const image = images[0] ? relPath(images[0].rel_path) : '//lakkspesialisten.no/media/dist/images/bilde_mangler.png';

    return (
        <>
            <div className={`col itemDescription ${pane === sideViews.ORDER ? "full" : "minimized"}`}>
                <div className="row">
                    <div className={`itemImg ${pane === sideViews.ORDER ? "col-md-3" : "col-md-6"}`}>
                        <img alt={name} src={`${image}?auto=compress&w=300`} style={{width: '100%'}}/>
                    </div>
                    <div className={`itemText ${pane === sideViews.ORDER ? "col-md-6" : "col-md-6"}`}>
                        <div>
                            <h4>{name}</h4>
                            <div>Art. nr.: <strong>{ProdNo}</strong></div>
                            {(!!info.TrInf2 && info.TrInf2 > 1) && <div>Antall i pk.: <strong>{info.TrInf2}</strong></div>}
                        </div>

                        <ShowMore length={80} text={info.TechnicalDetails}/>
                    </div>
                    <div className={`itemPrice ${pane === sideViews.ORDER ? "col-md-3" : "col-md-12"}`}>
                        <h4>
                            <strong><Nf number={pricing.price}/></strong>
                            <br/>
                            <small>(<Nf number={pricing.produkt_pris_ordinar}/>)</small>
                        </h4>
                        { replacement || <StockLines stock={stock}/> }
                    </div>
                </div>
                {Object.keys(fileTypes).length > 0 && (
                    <div className={'itemFiles'}>
                        <ListGroup flush>
                            {Object.keys(fileTypes).map(type => (
                                <div key={type}>
                                    <ListGroupItem disabled>
                                        <Icon icon={folder}/>{' '}
                                        <strong>{type}</strong>
                                    </ListGroupItem>
                                    {fileTypes[type].map(file => (
                                        <ListGroupItem key={file.rel_path}>
                                            <Icon icon={book}/>{' '}
                                            <a rel="noopener noreferrer"
                                               target="_blank"
                                               href={`//lakkspesialisten.no${file.rel_path}`}>
                                                {file.image}
                                            </a>
                                        </ListGroupItem>
                                    ))}
                                </div>
                            ))}
                        </ListGroup>
                    </div>
                )}
            </div>
            <Col className="itemAction">
                <FormGroup row>
                    <Col md={6}>
                        {hideWishListButton || <WishlistsDropdown disabled={false} lines={[info]}/>}
                    </Col>
                    <Col md={6} className="text-right">
                        <LabelsDropdown disabled={false} selection={{[ProdNo]: {}}}/>
                    </Col>
                </FormGroup>
                <Synonyms synonyms={tags} ProdNo={ProdNo} />
            </Col>
        </>
    );
};
