import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams, useRouter} from "./hooks/routerHooks";
import {
    asyncOrderFetch,
    asyncOrderLines,
} from "./reducers/loginActions";
import {actions, searchModes, sideViews} from "./reducers/configureStore";
import {Header} from "./Header";
import {BasicToolbox, LabelsDropdown, WishlistsDropdown} from "./Toolbox";
import {Button, ButtonGroup, Col, FormGroup, Row} from "reactstrap";
import MainPane from "./MainPane";
import {Icon} from "react-icons-kit";
import {
    chevronDown,
    chevronUp,
    edit,
    shoppingCart,
} from "react-icons-kit/feather";
import {OrderInfo} from "./OrderInfo";
import Nf from "./NF";
import {round} from "./OrderLine";
import {orderFilter} from "./App";
import {openInfoPane, setReduxQuery} from "./reducers/selectors";
import Input from "reactstrap/es/Input";
import SelectableList from "./SelectableList";
import {useSelection} from "./hooks/useSelection";
import {ToolboxCompanyWrapper} from "./ToolboxCompany";
import {OrderStatus} from "./ParkedOrders";
import {useCurrentOrder} from "./hooks/useCurrentOrder";

const OrderView = () => {

    const dispatch = useDispatch();

    const {match: {params: {uid, oid}}} = useRouter();
    const [pane, order] = useSelector(state => [state.pane, state.order || []]);

    useEffect(() => {

        dispatch({type: 'SET_UID', uid});
        dispatch(asyncOrderFetch(oid, uid));

        dispatch({type: 'SET_SEARCH_MODE', mode: searchModes.ORDER});

    }, [oid, uid, dispatch]);


    return (
        <>
            <Header/>
            <BasicToolbox component={ToolboxOrderHistory}/>
            <MainPane
                component={<Col className={pane === sideViews.ORDER ? 'order-pane largeview' : 'order-pane minified'}
                                md={pane === sideViews.ORDER ? 12 : 5}>
                    <ul>
                        <SelectableList
                            name="HISTORY_ORDER"
                            data={order.filter(orderFilter)}
                            comp={OrderLineView}
                            id={line => line.ordrelinje_nr}
                        />
                    </ul>
                </Col>}/>
            <OrderHistoryFooter/>
        </>

    );
};

export const ToolboxOrderHistory = () => {

    const pane = useSelector(s => s.pane);
    const dispatch = useDispatch();
    const {oid} = useSelector(s => s.currentOrderEdited);
    const {uid} = useParams();
    const {isEmpty, selected, clearSelection} = useSelection("HISTORY_ORDER");
    const {handleAddToOrder} = useCurrentOrder(uid);

    const disabled = isEmpty();
    const selection = Object.values(selected()).map(({produkt_nr}) => ({produkt_nr, amount: 1}));


    const labels = Object.values(selected()).reduce((acc, l) => {
        acc[l.produkt_nr] = {};
        return acc;
    }, {});

    const size = pane === sideViews.ORDER ? 5 : 5;

    const {navigate} = useLocation();


    const _handleAddToOrder = e => {

        dispatch({type: 'ADD_HIGHLIGHTED', products: Object.values(selected())});

        handleAddToOrder(selection).then((resp) => {
            dispatch({type: 'CLEAR_SEARCH_SELECTION'});
            dispatch({type: 'SET_ACTION', action: actions.NONE});
            dispatch(setReduxQuery(''));

            clearSelection();

            console.log(resp);

            const {uid, oid} = resp;

            navigate(`/orders/${uid}/edit/${oid}`);
        });
    };

    return (
        <Col md={size} sm={size} className="toolBoxRight">
            <ToolboxCompanyWrapper className="float-right" component={
                <ButtonGroup>
                    <LabelsDropdown selection={labels} disabled={disabled}/>
                    <WishlistsDropdown disabled={disabled} lines={selection}/>
                    <Button color="success" disabled={disabled} onClick={_handleAddToOrder}>
                            <Icon icon={shoppingCart}/>
                            {` Til ${oid || `ny`} ordre`}
                        </Button>
                </ButtonGroup>
            }/>
        </Col>
    );
};


const OrderLineView = ({item, toggle, selected}) => {

    const states = {
        CLOSED: 'CLOSED',
        PRODUCT: 'PRODUCT',
        COMMENTS: 'COMMENTS',
    };

    const is = selected(item.ordrelinje_nr);

    const handleToggle = () => toggle(item);

    const line = item;

    const dispatch = useDispatch();

    const [s, setS] = useState(states.CLOSED);

    const brutto = round(line.linje_sum / line.antall);

    let hasComment = line.externalComment && line.externalComment.length > 0;
    let hasInternalComment = line.internalComment && line.internalComment.length > 0;
    let hasBothComments = hasComment && hasInternalComment;

    const handlePaneState = type => e => {
        s === states.CLOSED && dispatch(openInfoPane(line.produkt_nr, 'order'));
        setS(s === type ? states.CLOSED : type);
    };


    return (
        <li className={`order-line ${is ? 'selected' : ''}`}>
            <Row className="no-gutters">

                <Col md={1} className="orderLineBtn orderLineExpandBtn text-center">
                    <Button id="caret" color="primary" outline

                            onClick={e => setS(s === states.PRODUCT ? states.CLOSED : states.PRODUCT)}>
                        <Icon size={28} icon={s === states.PRODUCT ? chevronUp : chevronDown}/>
                    </Button>
                </Col>
                <Col md={4} className="orderLineInfo text">
                    <strong onClick={handleToggle}>{line.produkt_navn}</strong><br/>
                    {line.produkt_nr}<br/>
                </Col>
                <Col md={2} className="orderLineInfo">

                    <div className="itemInfo itemAmount">
                        <Row noGutters>
                            <Col md={4}>Antall:</Col>
                            <Col md={8} className="text-right">{line.antall}</Col>
                        </Row>
                    </div>
                    <div className="itemInfo itemCode">
                        <Row noGutters>
                            <Col md={4}>Kode:</Col>
                            <Col md={8} className="text-right">{line.pricing.code}</Col>
                        </Row>
                    </div>

                </Col>
                <Col md={2} className="orderLineInfo">
                    <div className="itemInfo itemNet">
                        <Row noGutters>
                            <Col md={4}>Net:</Col>
                            <Col md={8} className="text-right">
                                <Nf number={line.produkt_pris_2}/>
                            </Col>
                        </Row>
                    </div>
                    <div className="itemInfo itemBrt">
                        <Row noGutters><Col md={4}>Brt:</Col><Col md={8} className="text-right">
                            <Nf number={brutto}/>
                        </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={2} className="orderLineInfo">
                    <div className="itemInfo itemRab">
                        <Row noGutters>
                            <Col md={4}>Rab:</Col>
                            <Col md={8} className="text-right">
                                {line.pricing.rabatt}{line.pricing.rabattEnhet}
                            </Col>
                        </Row>
                    </div>
                    <div className="itemInfo itemSum">
                        <Row noGutters>
                            <Col md={4}>Sum:</Col>
                            <Col md={8} className="text-right">
                                <span className="noWrap"><Nf number={line.linje_sum}/></span>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={1} className="orderLineBtn orderLineCommentBtn text-center">

                    <Button color="success" outline
                            onClick={handlePaneState(states.COMMENTS)}>
                        <Icon size={20} icon={edit}/>
                    </Button>
                    {hasBothComments && <div className="commentDot both"/>}
                    {(hasComment && !hasBothComments) && <div className="commentDot order"/>}
                    {(hasInternalComment && !hasBothComments) && <div className="commentDot internal"/>}

                </Col>

            </Row>

            {s === states.PRODUCT &&
            <OrderInfo ProdNo={line.produkt_nr} name={line.produkt_navn} hideWishListButton={false}/>}
            {s === states.COMMENTS && <Col className="itemComments">
                <FormGroup row>
                    <Col md={6} className="orderComment">
                        <label htmlFor="orderlineComment">Ordrelinjekommentar</label>
                        <Input type="textarea" readOnly value={line.externalComment || ''}/>
                    </Col>
                    <Col md={6} className="internalComment">
                        <label htmlFor="internalComment">Intern kommentar</label>
                        <Input type="textarea" value={line.internalComment || ''} readOnly/>
                    </Col>
                </FormGroup>
            </Col>}

        </li>
    )
};


function OrderHistoryFooter() {

    const order = useSelector(s => s.orderInfo) || {};

    return (
        <footer className={`footer`}>
            <div className="footerContainer clearfix">
                <Row className="orderFooterInfo">
                    <Col md={4} className="orderFooterSum">
                        Ordersum kr. <strong><Nf number={order.sum_total}/></strong>
                    </Col>
                    <Col md={4} className="text-right orderFooterSum">
                        Ordernummer <strong>{order.DATA_ALPH_1}</strong>
                    </Col>
                    <Col md={4} className="text-right orderFooterSum">
                        <Button color="light">
                        {order.status && <OrderStatus status={order.status} DATA_NUM_5={order.DATA_NUM_5} OrdTp={order.OrdTp}
                                     pno={order.DATA_NUM_6}/>}
                        </Button>
                    </Col>
                </Row>
            </div>
        </footer>
    );

}

export default OrderView;