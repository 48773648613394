import axios from "axios";
import {loadState} from "./reducers/localStorage";
import queryString from "query-string";
import {DateTimeFormatter, LocalDate} from "js-joda";

export const inStock = (value = null) => {
    if (value === null) {
        return !!(localStorage.getItem('inStock') || 1);
    }

    const ls = value | 0;
    localStorage.setItem('inStock', ls);

    return ls;
};

const token = loadState();

const instance = axios.create({
    baseURL: 'https://lakk.dna.argo.no/',
    timeout: 50000,
    credentials: true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json'

    }
});

const CancelToken = axios.CancelToken;
let cancelRequest = () => {};


export const emEvent = (name, value) => ({target: {name, value}});

export default {

    login(data) {

        const form = new FormData();
        form.append('username', data.username);
        form.append('password', data.password);

        return instance.post(`/@users/login`, form);
    },
    images(id, user) {
        return instance.get(`/-@-products/images?id=${encodeURIComponent(id)}&user=${user}`);
    },
    productTags(product, tags) {
        const form = new FormData();
        form.set('tags', tags);

        return instance.post(`/-@-products/tags/${encodeURIComponent(product)}`, form);
    },
    stats() {
        return instance.get(`/-@-stats`);
    },
    latest() {
        return instance.get(`/-@-stats/completed`);
    },
    price(id, user, amount) {
        return instance.get(`/-@-products/discount?${queryString.stringify({id, user, amount})}`);
    },
    logout() {
        return instance.post(`/-@-users/logout`);
    },
    token(token) {
        instance.headers = {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'

        }
    },
    parked(user) {
        return instance.get(`/-@-users/view/${user}`);
    },
    history(user, query = {}) {

        if (query.start instanceof Date) {
            const d = query.start.toISOString().replace(/(T.*)/, '');
            query.start = LocalDate.parse(d).format(DateTimeFormatter.ofPattern('dd.MM.yyyy'))
        }

        if (query.end instanceof Date) {
            const d = query.end.toISOString().replace(/(T.*)/, '');
            query.end = LocalDate.parse(d).format(DateTimeFormatter.ofPattern('dd.MM.yyyy'))
        }

        return instance.get(`/-@-users/history/${user}?${queryString.stringify(query)}`);
    },
    user(id) {
        return instance.get(`/-@-users/index/${id}`);
    },
    userSearch(query, fuery) {
        return instance.get(`/-@-users/search?q=${encodeURIComponent(query)}&f=${encodeURIComponent(fuery)}`);
    },
    userFavorites(user, way = null) {
        if(way === null) {
            return instance.get(`/-@-users/favorites/${user}`)
        }

        const formData = new FormData();
        formData.set('way', way);

        return instance.post(`/-@-users/favorites/${user}`, formData);


    },
    userComment(user, comment) {
        const formData = new FormData();
        formData.set('comment', comment);
        return instance.post(`/-@-users/comment/${user}`, formData);
    },
    search(q, w, l, f) {

        cancelRequest();

        return instance.get('/-@-search/find', {
            params: {q, w, l, f, index: 'sales_lakk', fromReact: true},
            cancelToken: new CancelToken(function executor(c) {
                cancelRequest = c;
            })
        });
    },
    order(id, user) {
        return instance.get(`/-@-orders/${user}/edit/${id}`);
    },
    orderAdd(id, user, data) {
        return instance.get(`/-@-orders/${user}/add-line/${id}?${queryString.stringify(data)}`);
    },
    orderLineDiscount(id, user, discount, line) {
        const formData = new FormData();
        formData.append("value", discount);
        formData.append("pk", line);

        return instance.post(`/-@-orders/${user}/change-discount/${id}`, formData);
    },
    orderLineComment(id, user, comment, line) {
        const formData = new FormData();
        const {ordercomment, internalComment} = comment;

        formData.append("comment", ordercomment);
        formData.append("internal", internalComment);
        formData.append("line", line);

        return instance.post(`/-@-orders/${user}/save-line-comment/${id}`, formData);
    },
    orderRemove(id, user) {
        return instance.get(`/-@-orders/${user}/delete/${id}`);
    },
    orderDelete(id, user, lineId) {
        return instance.get(`/-@-orders/${user}/delete-line/${id}?line=${lineId}`);
    },
    orderApprove(id, user, data) {
        const form = new FormData();

        Object.keys(data).map(key => data[key] && form.set(key, data[key]));
        return instance.post(`/-@-orders/${user}/approve/${id}`, form);
    },
    orderUpdate(id, user, type, value) {
        const types = {
            'DATA_ALPH_4': 'add-po',
            'best_alt_levering_kommentar': 'add-ref',
            'DATA_NUM_2': 'change-leveringsmaate',
            'OrdTp': 'addOffer',
            'QO': 'addQuick',
        };

        if (types[type]) {

            const data = new FormData();
            data.append(type, value);

            return instance.post(`/-@-orders/${user}/${types[type]}/${id}`, data);
        }
    },
    new(user) {
        return instance.get(`/-@-orders/${user}/new`);
    },
    wishlists(user) {
        return instance.get(`/-@-wishlist/${user}/wish-list`);
    },
    wishlist(user, id) {
        return instance.get(`/-@-wishlist/${user}/get/${id}`);
    },
    wishlistCreate(user, name) {
        const formData = new FormData();
        formData.append("name", name);

        return instance.post(`/-@-wishlist/${user}/create`, formData);
    },
    wishlistAdd(user, id, product, amount = 1) {
        const formData = new FormData();
        formData.append("identifier", product);
        formData.append("amount", amount);

        return instance.post(`/-@-wishlist/${user}/create-line/${id}`, formData);
    },
    wishlistEdit(user, id, line) {
        const formData = new FormData();
        formData.append("identifier", line.identifier);
        formData.append("amount", line.amount);
        formData.append("line", line.id);

        return instance.post(`/-@-wishlist/${user}/edit-line/${id}`, formData);
    },

    campaigns() {
        return instance.get(`/-@-stats/campaigns`);
    },
    wishlistDelete(user, id, line) {
        const formData = new FormData();
        formData.append("identifier", line.identifier);
        formData.append("amount", line.amount);
        formData.append("line", line.id);

        return instance.post(`/-@-wishlist/${user}/edit-line/${id}`, formData);
    },
    wl(user, id) {
        return {
            line(line = null) {
                return {
                    add(product, amount = '1') {
                        const formData = new FormData();
                        formData.append("identifier", product);
                        formData.append("amount", amount);

                        return instance.post(`/-@-wishlist/${user}/create-line/${id}`, formData);
                    },
                    edit() {
                        const formData = new FormData();
                        formData.append("identifier", line.identifier);
                        formData.append("amount", line.amount);
                        formData.append("line", line.id);

                        return instance.post(`/-@-wishlist/${user}/edit-line/${id}`, formData);
                    },
                    delete() {
                        const formData = new FormData();
                        formData.append("line", line.id);

                        return instance.post(`/-@-wishlist/${user}/delete-line/${id}`, formData);
                    }
                }
            },
            get() {
                return instance.get(`/-@-wishlist/${user}/get/${id}`);
            },
            create(id, data = {}) {
                const form = new FormData();
                Object.keys(data).map(key => form.set(key, data[key]));
                return instance.post(`/-@-wishlist/${user}/create`, form);
            },
            edit(id, data = {}) {
                const form = new FormData();
                Object.keys(data).map(key => form.set(key, data[key]));
                return instance.post(`/-@-wishlist/${user}/edit/${id}`, form);
            },
            delete() {
                return instance.post(`/-@-wishlist/${user}/delete/${id}`);
            }
        }
    }


}

