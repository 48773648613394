import {useDispatch, useSelector} from "react-redux";

export function useSelection(selector) {

    const selected = useSelector(state => state.selections[selector]) || {};
    const dispatch = useDispatch();

    return {
        toSelection: (id, line) => id && dispatch({type: 'SET_TO_SELECTION', selector, id, line}),
        isSelected: id => Object.keys(selected).indexOf(id) !== -1,
        fromSelection: id => dispatch({type: 'REMOVE_FROM_SELECTION', selector, id}),
        clearSelection: () => dispatch({type: 'CLEAR_SELECTION', selector}),
        isEmpty: () => Object.keys(selected).length === 0,
        selected : () => selected
    }

}