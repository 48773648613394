import {createStore, applyMiddleware, compose} from 'redux';
import {loadState, saveState} from "./localStorage";
import api from '../api';

import thunk from 'redux-thunk';
import {rootReducer as root} from './rootReducer';

// import logger from 'redux-logger'


export const actions = {
    NONE: 'NONE',
    LOGGING_IN: 'LOGGING_IN',
    LOGGING_ERROR: 'LOGGING_ERROR',
    LOGGING_OUT: 'LOGGING_OUT',
    USER_SEARCH: 'USER_SEARCH',
    USER_LOAD: 'USER_LOAD',
    PRODUCT_SEARCH: 'PRODUCT_SEARCH',
    WISHLIST_ADD: 'WISHLIST_ADD',
    ADD_TO_ORDER: 'ADD_TO_ORDER',
    DELETE_FROM_ORDER: 'DELETE_FROM_ORDER',
    ORDER_UPDATING: 'ORDER_UPDATING',
    HISTORY_SEARCH: 'HISTORY_SEARCH',
    SELECT_ALL: 'SELECT_ALL',
};

export const sideViews = {
    ORDER: 'order',
    WISHLIST: 'wishlist',
    SEARCH: 'search',
    USER: 'user'
};

export const searchModes = {
    PRODUCT: 'PRODUCT',
    USER: 'USER',
    ORDER: 'ORDER'
};


const persistedState = {
    uid: null,
    user: [],
    orderId: null,
    order: [],
    orderInfo: {},
    pane: sideViews.ORDER,
    action: actions.NONE,
    searchMode: searchModes.PRODUCT,
    query: '',
    userResults: [],
    searchResults: [],
    eanQuery: false,
    searchSelection: {},
    orderSelection: {},
    wishlistSelection: {},
    selections: {},
    opened: {
        order: null,
        search: null,
        wishlist: null
    },
    history: {
        start: '',
        end: '',
        contents: '',
        lines: false,
        order: '-best_dato',
        limit: 20,
        offset: 0
    },
    historyOrders: [],
    currentOrderEdited: {},
    wishlist: {},
    wishlists: [],
    wishlistsLoaded: false,
    wishlistDirty: true,
    orderHighlighted: [],
    token: loadState(),
    message: '',
    messageColor: 'info',
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const configuredStore = createStore(
    root,
    persistedState,
    composeEnhancers(applyMiddleware(thunk.withExtraArgument(api)))
    // , logger
);

configuredStore.subscribe(() => {
    saveState(configuredStore.getState());
});


