export const loadState = () => {

    try {
        const token = localStorage.getItem('token');
        if (token === null) {
            return '';
        }

        return token;
    } catch (err) {
        return '';
    }
};

export const saveState = state => {

    try {
        const token = state.token;
        localStorage.setItem('token', token || '');
    } catch (err) {
    }
};