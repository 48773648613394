import React from 'react';
import {useSelector} from "react-redux";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import LoginForm from "./LoginForm";
import App from "./App";
import ParkedOrders from "./ParkedOrders";
import Dashboard from "./Dashboard";
import OrderHistory from "./OrderHistory";
import OrderView from "./OrderView";
import WishList from "./WishList";
import WishListList from "./wishlists/WishListList";
import Campaigns from "./campaigns";

const Routes = () => {

    const token = useSelector(state => state.token);

    if (!token) {
        return <LoginForm/>;
    }

    return (
        <Router>
            <Switch>
                <Route exact path={"/user/:uid"} component={ParkedOrders}/>
                <Route exact path={"/campaigns"} component={Campaigns}/>
                <Route path={"/orders/:uid/edit/:oid"} component={App}/>
                <Route path={"/orders/:uid/view/:oid"} component={OrderView}/>
                <Route path={"/history/:uid"} component={OrderHistory}/>
                <Route exact path={"/wishlists/:uid"} component={WishListList}/>
                <Route path={"/wishlists/:uid/edit/:lid"} component={WishList}/>
                <Route path={"/"} component={Dashboard}/>

                <Route component={LoginForm}/>
            </Switch>
        </Router>
    );

};

export default Routes;