import {useDispatch, useSelector} from "react-redux";
import {Button, Input, InputGroup, InputGroupAddon, Modal, ModalBody, Row} from "reactstrap";
import {Icon} from "react-icons-kit";
import {box, xCircle, list, search, folder, pause, x} from "react-icons-kit/feather";
import React, {useState, useEffect, useRef} from "react";
import {asyncHistorySearch, asyncProductSearch, asyncUserSearch} from "./reducers/loginActions";
import {actions, searchModes} from "./reducers/configureStore";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {LocalDate, DateTimeFormatter, convert} from 'js-joda'
import {Link} from "react-router-dom";
import Campaigns from "./campaigns";
import {useParams} from "./hooks/routerHooks";
import {setReduxQuery} from "./reducers/selectors";

export function Header() {

    const [modal, setModal] = useState(false);

    const query = useSelector(s => s.query);

    const [user, setUser] = useState('');
    const [isInStock, setIsInStock] = useState((localStorage.getItem('inStock') || 1) === '1');

    const inputRef = useRef(null);

    const dispatch = useDispatch();
    const searchMode = useSelector(s => s.searchMode);

    const searchProduct = searchMode === searchModes.PRODUCT;

    const handleKeyup = e => {

        if (e.key === 'Escape') {

            searchProduct ? dispatch(setReduxQuery('')) : setUser('');
        }
    };

    const handleInStock = () => {
        localStorage.setItem('inStock', !isInStock | 0);
        setIsInStock(!isInStock);
    };

    useEffect(() => {

        if (query.length > 3) {
            dispatch(asyncProductSearch(query, 'Bergen', isInStock, inputRef.current && inputRef.current.value));
        } else {
            dispatch({type: 'SET_PRODUCT_SEARCH_RESULTS', products: []})
        }
    }, [query, isInStock, dispatch]);

    useEffect(() => {

        if (user.length > 3) {
            dispatch(asyncUserSearch(user, inputRef.current && inputRef.current.value));
        } else {
            dispatch({type: 'SET_USER_SEARCH_RESULTS', users: []})
        }

    }, [user, dispatch]);


    const placeholders = {
        [searchModes.ORDER]: 'Ordresøk',
        [searchModes.PRODUCT]: 'Produktsøk',
        [searchModes.USER]: 'Firmasøk',
    };


    return (
        <header id="top" className={`${searchMode.toLowerCase()}-search`}>
            <div className="container-fluid">
                <Row>
                    <div className="col-2 col-sm-2 col-md-1 col-xs-2" id="branding">
                        <div className="logo">
                            <Link to="/">
                                <img src="https://lakkspesialisten.no/media/fm/1231c24363.svg"
                                     alt="Lakkspesialisten Selgerløsning"/>
                            </Link>
                        </div>
                    </div>
                    <div className="col-8 col-sm-8 col-md-10 col-xs-8" id="mainSearch">

                        {searchMode === searchModes.ORDER &&
                        <OrderSearchInputGroup placeholder={placeholders[searchMode]}/>}
                        {searchMode !== searchModes.ORDER &&
                        <InputGroup>

                            <Input onKeyUp={handleKeyup} placeholder={placeholders[searchMode]}
                                   value={searchProduct ? query : user}
                                   innerRef={r => inputRef.current = r}
                                   onChange={e => {
                                       searchProduct ? dispatch(setReduxQuery(e.target.value)) : setUser(e.target.value);
                                   }}/>

                            {(query.length > 0 || user.length > 0) &&
                            <>
                                <InputGroupAddon addonType="append">
                                    <Button color="danger"
                                            onClick={e => searchProduct ? dispatch(setReduxQuery('')) : setUser('')}>
                                        <Icon icon={xCircle}/>
                                    </Button>
                                </InputGroupAddon>
                            </>
                            }
                            {
                                searchProduct &&
                                <InputGroupAddon addonType="append">
                                    <Button color={isInStock ? 'warning' : 'light'}
                                            onClick={handleInStock}>
                                        <Icon icon={box}/>
                                    </Button>
                                </InputGroupAddon>
                            }
                        </InputGroup>
                        }


                    </div>
                    <div className="col-2 col-md-1 col-sm-2 col-xs-2 mainNav">
                        <Button onClick={() => setModal(!modal)} className="bin btn-transparent">
                            <Icon size={28} icon={folder}/>
                        </Button>
                        <style>{`.modal-dialog { max-width: 90% }`}</style>
                        <CampaignModal modal={modal} setModal={setModal}/>
                    </div>
                </Row>
            </div>
        </header>

    );
}


const CampaignModal = ({modal, setModal}) => {

    return (
        <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
            <ModalBody>
                <Campaigns showHeader={false}/>
            </ModalBody>
        </Modal>
    );
};

function OrderSearchInputGroup({placeholder}) {

    const dispatch = useDispatch();
    const query = useSelector(s => s.history);
    const action = useSelector(s => s.action);
    const cq = useRef(query);
    const time = useRef(null);
    const {uid} = useParams();

    const props = {
        inputProps: {
            className: 'form-control'
        },
        placeholder: 'DD.MM.YYYY',
        formatDate: (date) => {
            const d = date.toISOString().replace(/(T.*)/, '');
            return LocalDate.parse(d).format(DateTimeFormatter.ofPattern('dd.MM.yyyy'))
        },
        parseDate: (str) => {
            const date = LocalDate.parse(str, DateTimeFormatter.ofPattern("dd.MM.yyyy"));
            convert(date).toDate();
        }
    };

    const handleChange = name => e => {
        dispatch({
            type: 'HISTORY_QUERY',
            name: name || e.target.name,
            value: e.target.value
        });

        cq.current[name || e.target.name] = e.target.value;

        if (time.current) {
            clearTimeout(time.current);
        }

        time.current = setTimeout(() => {
            dispatch(asyncHistorySearch(uid, cq.current));
        }, 1000);

    };

    return (

        <InputGroup>
            <Input placeholder={placeholder}
                   value={query.contents}
                   onChange={handleChange('contents')}/>
            <InputGroupAddon addonType="append">
                <Button size="sm"
                        color="light"
                        onClick={() => {
                            handleChange('contents')({target: {value: ''}});
                        }}
                >
                    <Icon icon={x}/>
                </Button>
            </InputGroupAddon>
            <DayPickerInput value={query.start}
                            onDayChange={d => handleChange('start')({target: {value: d}})}
                            {...props}/>
            <InputGroupAddon addonType="append">
                <Button size="sm"
                        color="light"
                        onClick={() => {
                            handleChange('start')({target: {value: ''}});
                        }}
                >
                    <Icon icon={x}/>
                </Button>
            </InputGroupAddon>
            <DayPickerInput value={query.end}
                            onDayChange={d => handleChange('end')({target: {value: d}})}
                            {...props}/>
            <InputGroupAddon addonType="append">
                <Button size="sm"
                        color="light"
                        onClick={() => handleChange('to')({target: {value: ''}})}
                >
                    <Icon icon={x}/>
                </Button>
            </InputGroupAddon>
            <InputGroupAddon addonType="append">
                <Button color={query.lines ? 'success' : 'light'}
                        onClick={() => handleChange('lines')({target: {value: !query.lines}})}>
                    <Icon icon={list}/>
                </Button>
            </InputGroupAddon>
            <InputGroupAddon addonType="append">
                <Button disabled={action === actions.HISTORY_SEARCH}
                        color="info" onClick={() => dispatch(asyncHistorySearch(uid, query))}>
                    <Icon icon={action === actions.HISTORY_SEARCH ? pause : search}/>
                </Button>
            </InputGroupAddon>


        </InputGroup>
    );

}

