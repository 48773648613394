import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useRouter} from "./hooks/routerHooks";
import {searchModes, sideViews} from "./reducers/configureStore";
import api from './api';
import {Link} from "react-router-dom";
import {Icon} from "react-icons-kit";
import {shoppingCart, printer, atSign} from "react-icons-kit/feather";
import {LocalDateTime, DateTimeFormatter, LocalDate} from 'js-joda'
import {Button, Col, Row, Badge} from "reactstrap";

import {Header} from "./Header";
import MainPane from "./MainPane";
import {ToolboxInfo} from "./ToolboxInfo";
import Nf from "./NF";
import ToolboxCompany from "./ToolboxCompany";
import {useSelection} from "./hooks/useSelection";
import {useCurrentOrder} from "./hooks/useCurrentOrder";

function formatDate(date, wt = true) {

    try {
        const formatter = DateTimeFormatter.ofPattern(wt ? 'dd.MM.yy HH:mm' : 'dd.MM.yy');
        return wt
               ? LocalDateTime.parse(date.replace(/ /, 'T')).format(formatter)
               : LocalDate.parse(date).format(formatter);
    } catch (e) {
        return '';
    }
}

export const ParkedOrdersToolbox = () => {

    return (
        <div className="main-container container-fluid">
            <Row>
                <ToolboxInfo md={12}/>
                <Col md={12} className="text-center toolBoxRight companyMenu">
                    <ToolboxCompany/>
                </Col>
            </Row>
        </div>
    );
};


const ParkedOrders = () => {

    const {match: {params: {uid}}} = useRouter();

    const dispatch = useDispatch();
    const [orders, setOrders] = useState([]);

    dispatch({type: 'SET_SEARCH_MODE', mode: searchModes.USER});
    dispatch({type: 'SET_UID', uid});

    useEffect(() => {
        api.parked(uid).then(({data: {orders}}) => setOrders(orders));
    }, [uid]);

    return (
        <>
            <Header/>
            <ParkedOrdersToolbox/>
            <MainPane component={<OrdersTable orders={orders} uid={uid}/>}/>

        </>
    );
};

export const OrderStatus = ({status, pno, OrdTp, DATA_NUM_5}) => {
    let [label, color] = status === 'complete'
                         ? ['Bestilt', 'primary']
                         : [status.charAt(0).toUpperCase() + status.slice(1), 'success'];

    if (OrdTp === '5') {
        label = 'Tilbud';
        color = 'warning';
    }

    return (
        <div className={"order-status"}>
            {
                label === 'Incomplete' || <Badge color={color}>
                    {label}
                </Badge>
            }
            {pno && <Icon className="text-success" icon={printer}/>}
            {DATA_NUM_5 > 0 && <Icon className="text-warning" icon={atSign}/>}
        </div>

    );
};

export function OrdersTable({orders, uid, type = "edit", lines}) {

    const pane = useSelector(s => s.pane);

    return (
        <Col className={pane === sideViews.ORDER ? 'order-pane largeview' : 'order-pane minified'}
             md={pane === sideViews.ORDER ? 12 : 5}>
            <table className="table table-sm">
                <thead>
                <tr>
                    {pane === sideViews.ORDER && type === 'view' && <th>Visma</th>}
                    <th>Web</th>
                    {/*<th>Oprettet</th>*/}
                    {type === 'edit' && <th>Endret</th>}
                    {type === 'view' && <th>Best. Dato</th>}
                    {pane === sideViews.ORDER && <th>Antall</th>}
                    {pane === sideViews.ORDER && <th className={"text-right"}>Sum</th>}
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>
                {orders.map(order => <HistoryOrderLine type={type} lines={lines} order={order} uid={uid}
                                                       key={order.ordre_nr}/>)}
                </tbody>
            </table>
        </Col>
    );
}


export const HistoryOrderLine = ({order, uid, type, lines}) => {

    const pane = useSelector(s => s.pane);
    const nowrap = {whiteSpace: 'nowrap'};
    const displayLines = order => type === 'view' && lines && order.lines.length > 0;
    const currentOrderEdited = useSelector(s => s.currentOrderEdited);
    const {isSelected, toSelection, clearSelection} = useSelection("HISTORY_ADD_TO_CART");
    const {navigate} = useLocation();
    const {handleAddToOrder} = useCurrentOrder(uid);

    const highlight = (order.highlight || '').split(',');
    const query = useSelector(state => state.history);
    const highlighted = ol => query.contents.length > 0 && highlight.includes(ol + "");

    return (
        <>
            <tr className={displayLines(order) ? 'bg-warning' : ''}>
                {pane === sideViews.ORDER && type === 'view' && <td>
                    <Link to={`/orders/${uid}/${type}/${order.ordre_nr}`}>
                        {order.DATA_ALPH_1 || '-'}
                    </Link>
                </td>}
                <td>
                    <Link to={`/orders/${uid}/${type}/${order.ordre_nr}`}>
                        {order.ordre_nr}
                    </Link>
                </td>
                <td style={nowrap}>
                    {type === 'edit' && formatDate(order.endret)}
                    {type === 'view' && formatDate(order.best_dato, false)}
                </td>
                {pane === sideViews.ORDER &&
                <td style={nowrap}>{order.products} / {parseInt(order.products_total + 0, 10)}</td>}
                {pane === sideViews.ORDER && <td style={nowrap} className={"text-right"}>
                    <strong>
                        <Nf number={order.sum_total}/>
                    </strong>
                </td>}
                <td style={nowrap}>
                    <OrderStatus status={order.status} DATA_NUM_5={order.DATA_NUM_5} OrdTp={order.OrdTp}
                                 pno={order.DATA_NUM_6}/>
                </td>
            </tr>
            {displayLines(order) && <>{order.lines.map(line => (
                <tr key={line.ordrelinje_nr} style={{ backgroundColor : highlighted(line.ordrelinje_nr) ? 'lightgreen' : 'white'}}>
                    <td>
                        {line.produkt_nr}
                    </td>
                    <td colSpan={2}>{line.produkt_navn}</td>
                    <td><span className={"mono"}>{line.antall}</span></td>
                    <td style={nowrap} className={"text-right"}>
                        <Nf number={line.linje_sum}/>
                    </td>
                    <td>
                        {isSelected(line.produkt_nr)
                         ? (
                             <Button size="sm" color="success"
                                     onClick={() => clearSelection() && navigate(`/orders/${uid}/edit/${currentOrderEdited.oid}`)}>
                                 Gå til ordre
                             </Button>
                         )
                         : (
                             <Button size="sm"
                                     color="info"
                                     onClick={() => {
                                         toSelection(line.produkt_nr, {});
                                         handleAddToOrder([{
                                             produkt_nr: line.produkt_nr,
                                             amount: 1
                                         }]);
                                     }}>
                                 <Icon icon={shoppingCart}/>
                             </Button>
                         )}
                    </td>
                </tr>
            ))}</>}
        </>

    );


};

export default ParkedOrders;