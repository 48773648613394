import {actions} from "./configureStore";

export const asyncUserFetch = user => (dispatch, setState, api) => {

    dispatch({type: 'SET_ACTION', action: actions.USER_LOAD});

    const p = api.user(user);

    p.then(({data}) => {
        dispatch({type: 'SET_ACTION', action: actions.NONE});
        dispatch({type: 'SET_USER', user: data.user});
    });

    return p;
};

export const asyncUserComments = (user, comment) => (dispatch, setState, api) => {

    dispatch({type: 'SET_ACTION', action: actions.USER_LOAD});

    const p = api.userComment(user, comment);

    p.then(({data}) => {
        dispatch({type: 'SET_ACTION', action: actions.NONE});
        dispatch({type: 'SET_USER', user: data.user});
    });

    return p;
};

export const asyncUserFav = (user, way) => (dispatch, setState, api) => {

    dispatch({type: 'SET_ACTION', action: actions.USER_LOAD});

    const p = api.userFavorites(user, way);

    p.then(({data}) => {
        dispatch({type: 'SET_ACTION', action: actions.NONE});
        dispatch({type: 'SET_USER', user: data.user});
    });

    return p;
};

export const asyncUserSearch = (query, fuery) => (dispatch, setState, api) => {

    dispatch({type: 'SET_ACTION', action: actions.USER_SEARCH});

    api.userSearch(query, fuery).then(({data}) => {
        dispatch({type: 'SET_ACTION', action: actions.NONE});
        dispatch({type: 'SET_USER_SEARCH_RESULTS', users: data.users})
    });
};

export const asyncProductSearch = (query, wh, lager, fuery) => (dispatch, setState, api) => {

    dispatch({type: 'SET_ACTION', action: actions.PRODUCT_SEARCH});

    api.search(query, wh, lager, fuery)
       .then(({data: {products, eanQuery}}) => {
           dispatch({type: 'SET_PRODUCT_SEARCH_RESULTS', products, eanQuery});
           dispatch({type: 'CLEAR_SEARCH_SELECTION'});
           dispatch({type: 'SET_EAN_QUERY', eanQuery});
       })
       .catch(() => {})
       .finally(() => dispatch({type: 'SET_ACTION', action: actions.NONE}));
};

export const asyncWishLists = (uid) => (dispatch, setState, api) => {

    api.wishlists(uid).then(({data: {wishlists}}) => {
        dispatch({type: 'SET_WISHLISTS', wishlists});
        dispatch({type: 'SET_WISHLIST_DIRTY', wishlistDirty: true})
    });
};

export const asyncAddToWishList = (user, id, lines) => (dispatch, setState, api) => {

    return Promise.all(lines.map(line => api.wl(user, id).line().add(line.product, line.amount || 1)));
};

export const asyncDeleteFromWishList = (user, id, lines) => (dispatch, setState, api) => {
    return Promise.all(lines.map(line => api.wl(user, id).line(line).delete()));
};

export const asyncDeleteWishList = (user, id) => (dispatch, setState, api) => {
    return api.wl(user, id).delete();
};

export const asyncNotify = (message, color = 'info') => (dispatch) => {
    dispatch({type: 'ADD_NOTIFICATION', message, color});
};

export const asyncOrderLines = (id, user, lines, clear = true) => (dispatch, setState, api) => {

    dispatch({type: 'SET_ACTION', action: actions.ADD_TO_ORDER});

    return Promise.all(lines.map(line => api.orderAdd(id, user, line)))
                  .then(() => dispatch(asyncOrderFetch(id, user)))
                  .finally(() => {
                      dispatch({type: 'SET_ACTION', action: actions.NONE});
                      clear && dispatch({type: 'CLEAR_ORDER_SELECTION'});
                      clear && dispatch({type: 'CLEAR_WISHLIST_SELECTION'});
                  });
};

export const asyncSaveTags = (product, tags) => (dispatch, setState, api) => {

    return api.productTags(product, tags);
};

export const asyncOrderLineDiscount = (id, user, discount, line) => (dispatch, setState, api) => {
    return api.orderLineDiscount(id, user, discount, line)
              .then(() => dispatch(asyncOrderFetch(id, user)))
};

export const asyncOrderLineComment = (id, user, comment, line) => (dispatch, setState, api) => {

    return api.orderLineComment(id, user, comment, line)
              .then(() => dispatch(asyncOrderFetch(id, user)))
};

export const asyncOrderLinesEdit = (id, user, lines) => (dispatch, setState, api) => {

    dispatch({type: 'SET_ACTION', action: actions.ADD_TO_ORDER});

    return Promise.all(lines.map(line => api.orderAdd(id, user, line)))
                  .then(() => dispatch(asyncOrderFetch(id, user)))
                  .finally(() => {
                      dispatch({type: 'SET_ACTION', action: actions.NONE});
                      dispatch({type: 'CLEAR_ORDER_SELECTION'});
                      dispatch({type: 'CLEAR_WISHLIST_SELECTION'});
                  });
};

export const asyncOrderLinesDelete = (id, user, lines) => (dispatch, setState, api) => {

    dispatch({type: 'SET_ACTION', action: actions.DELETE_FROM_ORDER});

    return Promise.all(lines.map(line => api.orderDelete(id, user, line)))
                  .then(() => dispatch(asyncOrderFetch(id, user)))
                  .finally(() => {
                      dispatch({type: 'SET_ACTION', action: actions.NONE});
                      dispatch({type: 'CLEAR_ORDER_SELECTION'});
                      dispatch({type: 'CLEAR_WISHLIST_SELECTION'});
                  });
};

export const asyncOrderDelete = (id, user) => (dispatch, setState, api) => {

    dispatch({type: 'SET_ACTION', action: actions.ORDER_UPDATING});

    const promise = api.orderRemove(id, user);

    promise.finally(() => {
        dispatch({type: 'SET_ACTION', action: actions.NONE});
        dispatch({type: 'CLEAR_ORDER_SELECTION'});
        dispatch({type: 'CLEAR_WISHLIST_SELECTION'});
        dispatch({type: 'CLEAR_SEARCH_SELECTION'});
        dispatch({type: 'CLEAR_SEARCH_SELECTION'});
        dispatch({type: 'SET_CURRENT_ORDER_EDITED', currentOrderEdited: {}});
    });

    return promise;
};

export const asyncOrderUpdate = (id, user, type, value) => (dispatch, setState, api) => {

    dispatch({type: 'SET_ACTION', action: actions.ORDER_UPDATING});

    api.orderUpdate(id, user, type, value).then(() => {
        // dispatch(asyncOrderFetch(id, user));
    })
};

export const asyncOrderApprove = (id, user, data) => (dispatch, setState, api) => {

    dispatch({type: 'SET_ACTION', action: actions.ORDER_UPDATING});

    const promise = api.orderApprove(id, user, data);

    promise.then(() => {
        dispatch({type: 'SET_ACTION', action: actions.NONE});
        dispatch({type: 'CLEAR_ORDER_SELECTION'});
        dispatch({type: 'CLEAR_WISHLIST_SELECTION'});

        dispatch({type: 'RESET_ORDER'});
    });

    return promise;

};


export const asyncUserLogin = creds => (dispatch, setState, api) => {

    dispatch({type: 'SET_ACTION', action: actions.LOGGING_IN});

    api.login(creds).then(({data}) => {
        if (data.identity && data.identity.key) {
            api.token(data.identity.key);
            dispatch({type: 'SET_TOKEN', token: data.identity.key});
            dispatch({type: 'SET_ACTION', action: actions.NONE});

            window.location.reload();

        } else {
            dispatch({type: 'SET_ACTION', action: actions.LOGGING_ERROR});
        }
    });
};

export const asyncUserLogout = () => (dispatch, setState, api) => {
    dispatch({type: 'SET_ACTION', action: actions.LOGGING_OUT});
    api.logout().then(() => {
        dispatch({type: 'SET_ACTION', action: actions.NONE});
        dispatch({type: 'SET_TOKEN', token: null});
    });
};

export const asyncOrderFetch = (oid, uid) => (dispatch, setState, api) => {

    api.order(oid, uid).then(({data}) => {
        dispatch({type: 'SET_ACTION', action: actions.NONE});
        dispatch({type: 'SET_ORDER', order: data.lines});
        dispatch({type: 'SET_ORDER_INFO', order: data.order});
        dispatch({type: 'SET_ORDER_ID', order: data.order.ordre_nr});
    });

};


export const asyncHistorySearch = (uid, query) => (dispatch, setState, api) => {

    dispatch({type: 'SET_ACTION', action: actions.HISTORY_SEARCH});

    return api.history(uid, query)
              .then(({data: {orders}}) => {
                  dispatch({type: 'SET_HISTORY_ORDERS', historyOrders: orders});
                  dispatch({type: 'SET_ACTION', action: actions.NONE});
              })
              .catch(reason => {});
};