import React, {useState} from 'react';
import {Button, Input, FormGroup, Container, Form} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {actions} from "./reducers/configureStore";
import {asyncUserLogin} from "./reducers/loginActions";
import {Redirect} from "react-router-dom";


const LoginForm = () => {

    const [creds, setCreds] = useState({username: '', password: ''});

    const action = useSelector(s => s.action);
    const token = useSelector(s => s.token);
    const dispatch = useDispatch();

    function handleChange(e) {
        setCreds({...creds, [e.target.name]: e.target.value});
    }

    function handleSubmit(e) {

        e.preventDefault();

        dispatch(asyncUserLogin(creds));

        // setRedirect();

    }

    if (token) {
        return <Redirect to='/'/>;
    }


    return (
        <Container className="loginPage container-fluid">
            <div className="row align-items-center no-gutters">
                <div className="col"/>
                <div className="col-md-6 col-sm-6 col-xs-6">
                    <div className="logInBox">
                    <div className="logInLogo">
                        <img src="https://lakkspesialisten.no/assets/images/lakkspesialisten_logo_slogan.svg"
                                     alt="Lakkspesialisten Selgerløsning"/>
                                     <span className="logoSubtitle">Selgerløsning</span>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <label> Brukernavn
                                <Input name="username" value={creds.username} onChange={handleChange}/>
                            </label>
                        </FormGroup>
                        <FormGroup>
                            <label> Passord
                                <Input onChange={handleChange} value={creds.password} name="password" type="password"/>
                            </label>
                        </FormGroup>
                        <FormGroup>
                            <Button disabled={action === actions.LOGGING_IN} type="submit" color="warning" block>
                                Logg inn
                            </Button>
                        </FormGroup>
                    </Form>
                    </div>
                </div>
                <div className="col"></div>
            </div>
        </Container>
    );
};

export default LoginForm;