import {useDispatch, useSelector} from "react-redux";
import {asyncOrderLines} from "../reducers/loginActions";
import api, {inStock} from "../api";


export function useCurrentOrder(uid) {

    const currentOrderEdited = useSelector(state => state.currentOrderEdited) || {};
    const dispatch = useDispatch();

    const handleAddToOrder = lines => {

        return new Promise(resolve => {
            if (currentOrderEdited.oid) {

                dispatch(asyncOrderLines(currentOrderEdited.oid, uid, lines))
                    .then(() => resolve({uid, oid: currentOrderEdited.oid}));

            } else {
                api.new(uid).then(({data: {order}}) => {

                    inStock(true);

                    dispatch({type: 'SET_CURRENT_ORDER_EDITED', currentOrderEdited: {oid:order, uid}});

                    dispatch(asyncOrderLines(order, uid, lines)).then(() => resolve({uid, oid: order}));
                });
            }
        });
    };

    return {handleAddToOrder}
}