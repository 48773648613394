import {sideViews} from "./configureStore";

const actionPerformers = {
    SET_USER: ['user', 'user'],
    SET_SEARCH_MODE: ['searchMode', 'mode'],
    SET_ORDER_INFO: ['orderInfo', 'order'],
    SET_ACTION: ['action', 'action'],
    SET_TOKEN: ['token', 'token'],
    SET_UID: ['uid', 'uid'],
    SET_ORDER: ['order', 'order'],
    SET_ORDER_ID: ['orderId', 'order'],
    SET_PANE: ['pane', 'pane'],
    SET_WISHLISTS: ['wishlists', 'wishlists'],
    SET_WISHLIST: ['wishlist', 'wishlist'],
    SET_WISHLISTS_LOADED: ['wishlistsLoaded', 'wishlistsLoaded'],
    SET_WISHLIST_DIRTY: ['wishlistDirty', 'wishlistDirty'],
    SET_HISTORY_ORDERS: ['historyOrders', 'historyOrders'],
    SET_HISTORY_QUERY: ['history', 'history'],
    SET_QUERY: ['query', 'query'],
    SET_CURRENT_ORDER_EDITED: ['currentOrderEdited', 'currentOrderEdited'],
    SET_EAN_QUERY: ['eanQuery', 'eanQuery'],
};

export const rootReducer = (state = {}, action) => {

    if (actionPerformers[action.type]) {
        return {...state, [actionPerformers[action.type][0]]: action[actionPerformers[action.type][1]]}
    }

    if (action.type === 'RESET_ORDER') {
        return {...state, orderId: null, order: [], orderInfo: {}};
    }


    if (action.type === 'SET_TO_SELECTION') {
        const selections = {
            ...state.selections,
            [action.selector]: {...state.selections[action.selector], [action.id]: action.line}
        };

        return {...state, selections};
    }

    if (action.type === 'CLEAR_SELECTION') {
        const selections = {
            ...state.selections,
            [action.selector]: {}
        };

        return {...state, selections}
    }

    if (action.type === 'REMOVE_FROM_SELECTION') {

        const selector = state.selections[action.selector];
        delete selector[action.id];

        const selections = {...state.selections, [action.selector]: {...selector}};

        return {...state, selections}
    }

    if (action.type === 'HISTORY_QUERY') {
        return {...state, history: {...state.history, [action.name]: action.value}}
    }


    if (action.type === 'ADD_NOTIFICATION') {
        return {...state, message: action.message, messageColor: action.color}
    }

    if (action.type === 'ADD_HIGHLIGHTED') {

        const hl = state.orderHighlighted.concat(...action.products);

        const orderHighlighted = [...new Set(hl)];
        return {...state, orderHighlighted}
    }

    if (action.type === 'SET_WISHLISTS') {
        return {...state, wishlists: action.wishlists, wishlistsLoaded: true}
    }

    if (action.type === 'OPEN_INFO_PANE') {
        const opened = {...state.opened, [action.pane]: action.identifier};
        return {...state, opened}
    }

    if (action.type === 'REMOVE_HIGHLIGHTED') {

        const orderHighlighted = state.orderHighlighted.filter(item => action.products.indexOf(item) === -1);

        return {...state, orderHighlighted}
    }


    if (action.type === 'SET_TO_SEARCH_SELECTION') {

        const searchSelection = {...state.searchSelection, [action.line.produkt_nr]: action.line};
        return {...state, searchSelection}
    }

    if (action.type === 'CLEAR_SEARCH_SELECTION') {
        return {...state, ...{searchSelection: {}}}
    }

    if (action.type === 'CLEAR_ORDER_SELECTION') {

        const orderSelection = {};
        return {...state, orderSelection}
    }

    if (action.type === 'REMOVE_FROM_SEARCH_SELECTION') {

        const searchSelection = {...state.searchSelection};
        delete searchSelection[action.line.produkt_nr];

        return {...state, searchSelection}
    }

    if (action.type === 'SET_TO_ORDER_SELECTION') {
        const orderSelection = {...state.orderSelection, [action.line.produkt_nr]: action.line};
        return {...state, orderSelection}

    }

    if (action.type === 'REMOVE_FROM_WISHLIST_SELECTION') {

        const wishlistSelection = {...state.wishlistSelection};
        delete wishlistSelection[action.line.identifier];

        return {...state, wishlistSelection}
    }

    if (action.type === 'SET_TO_WISHLIST_SELECTION') {
        const wishlistSelection = {...state.wishlistSelection, [action.line.identifier]: action.line};
        return {...state, wishlistSelection}

    }

    if (action.type === 'CLEAR_WISHLIST_SELECTION') {
        return {...state, ...{wishlistSelection: {}}}
    }


    if (action.type === 'REMOVE_FROM_ORDER_SELECTION') {
        const orderSelection = {...state.orderSelection};
        delete orderSelection[action.line.produkt_nr];

        return {...state, orderSelection}
    }

    if (action.type === 'SET_PRODUCT_SEARCH_RESULTS') {

        let pane          = sideViews.ORDER,
            searchResults = action.products;

        if (searchResults.length > 0 || action.eanQuery) {
            pane = sideViews.SEARCH;
        }

        return {...state, pane, searchResults}
    }


    if (action.type === 'SET_USER_SEARCH_RESULTS') {

        let pane        = sideViews.ORDER,
            userResults = action.users;

        if (userResults.length > 0) {
            pane = sideViews.USER;
        }

        return {...state, pane, userResults}
    }


    if (action.type === 'REMOVE_ORDER_LINES') {
        const order = state.order.map(l => {

            const toRemove = action.lines.find(line => l.produkt_nr === line.produkt_nr);

            if (!toRemove) {
                return l;
            }

            return undefined;
        }).filter(e => e);

        return {...state, order}
    }

    if (action.type === 'SET_ORDER_LINES') {


        let order = [...state.order];


        action.lines.map(line => {
            const existing = state.order.find(l => l.produkt_nr === line.produkt_nr);


            if (existing) {

                existing.antall += line.antall;
                existing.linje_sum = existing.pris_ordinar * existing.antall;
                existing.isNew = true;
            } else {
                order = [line, ...order];
            }

            return true;
        });

        return {...state, order}
    }

    return state;
};