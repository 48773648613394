import React, {useState} from 'react';
import {Button, Input, InputGroup, InputGroupAddon} from "reactstrap";
import {Icon} from 'react-icons-kit'
import {plus} from 'react-icons-kit/feather'

const InputWithButton = ({amount, limit, incrementor = 1, handleChange, disableButton, ...rest}) => {

    const [inp, setInp] = useState(amount);
    const [value, setValue] = useState(amount);

    const types = {
        INPUT: 'INPUT',
        INC: 'INC'
    };

    const internalHandleChange = type => e => {

        if (type === types.INPUT && !e.target.value) {
            setInp(e.target.value);
            return;
        }

        let newValue = parseFloat(inp);

        if (type === types.INC) {

            const inc = incrementor > 1
                        ? incrementor
                        : 1 / [100, 10, 1].reduce((a, i) => newValue * i === parseInt(newValue * i, 10) ? i : a, 1);

            newValue += inc;

            if (limit > 0 && newValue > limit) {
                newValue = limit;
            }

            if (handleChange) {
                handleChange(newValue);
            }
        }

        if (type === types.INPUT) {

            setInp(e.target.value);

            if (incrementor > 1) {
                newValue = Math.round(parseFloat(e.target.value) / incrementor) * incrementor;
            } else {
                newValue = parseFloat(e.target.value);
            }

            if (limit > 0 && newValue > limit) {
                newValue = limit;
            }
        }

        if (value !== newValue) {
            setValue(newValue);
        }
    };

    const handleBlur = e => {
        if (e.target.value !== value) {
            setValue(e.target.value);
        }

        if (handleChange) {
            handleChange(e.target.value);
        }

    };

    return (
        <InputGroup>
            <Input onBlur={handleBlur} onChange={internalHandleChange(types.INPUT)} value={inp} {...rest} />
            {
                disableButton ||
                <InputGroupAddon addonType="append">
                    <Button color="primary" onClick={internalHandleChange(types.INC)}>
                        <Icon size={20} icon={plus}/>
                    </Button>
                </InputGroupAddon>
            }

        </InputGroup>
    );
};

export default InputWithButton;