import React, {useEffect, useState} from 'react';
import {Header} from "./Header";
import MainPane from "./MainPane";
import {HistoryOrderLine, ParkedOrdersToolbox} from "./ParkedOrders";
import {useParams} from "./hooks/routerHooks";
import {actions, searchModes} from "./reducers/configureStore";
import {useDispatch, useSelector} from "react-redux";
import {asyncHistorySearch} from "./reducers/loginActions";
import {Button, ButtonGroup, Col} from "reactstrap";
import {Icon} from "react-icons-kit";
import {chevronDown, chevronUp, arrowRight, arrowLeft} from "react-icons-kit/feather";
import api from "./api";
import {setReduxQuery} from "./reducers/selectors";

const OrderHistory = () => {

    const {uid} = useParams();

    const dispatch = useDispatch();
    const orders = useSelector(state => state.historyOrders);
    const query = useSelector(state => state.history);
    const loading = useSelector(state => state.action) === actions.HISTORY_SEARCH;

    dispatch(setReduxQuery(''));
    dispatch({type: 'SET_PRODUCT_SEARCH_RESULTS', products: []});
    dispatch({type: 'SET_USER_SEARCH_RESULTS', users: []});

    dispatch({type: 'SET_SEARCH_MODE', mode: searchModes.ORDER});
    dispatch({type: 'SET_UID', uid});

    const [hasNextPage, setHasNextPage] = useState(true);
    const [page, setPage] = useState(0);

    const handleLoadMore = (p) => {

        console.log('page ' + p);

        dispatch({type: 'SET_ACTION', action: actions.HISTORY_SEARCH});

        return api.history(uid, {...query, offset: p * query.limit})
                  .then(({data}) => {

                      dispatch({type: 'SET_ACTION', action: actions.NONE});

                      if (data.orders.length === 0) {
                          setHasNextPage(false);
                          return;
                      }
                      setPage(p);

                      dispatch({type: 'SET_HISTORY_ORDERS', historyOrders: data.orders});

                  });

    };

    useEffect(() => {

        dispatch(asyncHistorySearch(uid, {...query, offset: page * query.limit}));

    }, []);


    const items = orders.map(order => <HistoryOrderLine type={'view'} lines={query.lines} order={order} uid={uid}
                                                        key={order.ordre_nr}/>);

    const createHead = (name, title, cl = '') => {

        const [on, dir] = query.order.charAt(0) === '-' ? [query.order.slice(1), '-'] : [query.order, ''];
        const isName = on === name;
        const fo = isName && dir === '-' ? '' : '-';

        return (
            <th className={cl}>
                <Button
                    color={"link"}
                    onClick={() => {
                        dispatch({type: 'SET_HISTORY_QUERY', history: {...query, offset: 0, order: fo + name}});
                        dispatch(asyncHistorySearch(uid, {...query, offset: 0, order: fo + name}));
                        setPage(0);
                        setHasNextPage(true);
                    }}
                >
                    {isName && <Icon icon={dir === '-' ? chevronDown : chevronUp}/>}
                    {title}
                </Button>
            </th>
        );
    };

    const styles = {
        opacity: loading ? 0.2 : 1,
        color: loading ? '#f1f1f1' : 'inherit'
    };

    const ScrollWrapper = () => (
        <Col className="order-pane largeview" md={12}>
            <table className="table table-sm">
                <thead>
                <tr>
                    {createHead('DATA_ALPH_1', 'Visma')}
                    {createHead('ordre_nr', 'Web')}
                    {createHead('best_dato', 'Best. Dato')}
                    {createHead('products_total', 'Antall')}
                    {createHead('sum_total', 'Sum', 'text-right')}
                    {createHead('status', 'Status')}
                </tr>
                </thead>
                <tbody style={styles}>
                {items}
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={6}>
                        <ButtonGroup>
                            {page > 0 && (<Button disabled={loading} outline color="primary"
                                                  onClick={e => handleLoadMore(page - 1)}>
                                <Icon icon={arrowLeft}/> Forrige
                            </Button>)
                            }

                            {hasNextPage && (
                                <Button disabled={loading} outline color="success"
                                        onClick={e => handleLoadMore(page + 1)}>
                                    Neste <Icon icon={arrowRight}/>
                                </Button>)
                            }

                        </ButtonGroup>
                    </td>
                </tr>
                </tfoot>
            </table>
        </Col>
    );


    return (
        <>
            <Header/>
            <ParkedOrdersToolbox/>
            <MainPane component={<ScrollWrapper/>}/>

        </>
    );
};


export default OrderHistory;