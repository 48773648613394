import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Col, Row} from "reactstrap";
import {Icon} from "react-icons-kit";
import {shoppingCart,chevronDown,chevronUp} from "react-icons-kit/feather";
import {asyncOrderLines} from "../reducers/loginActions";
import {useInfo} from "../hooks/useInfo";
import Nf from "../NF";
import {getDefaultStock, openInfoPane} from "../reducers/selectors";
import {OrderInfo} from "../OrderInfo";
import InputWithButton from "../InputWithButton";
import {sideViews} from "../reducers/configureStore";



export const WishListLine = ({line}) => {

    const dispatch = useDispatch();
    const [selection, oid, uid] = useSelector(s => [s.wishlistSelection, s.orderId, s.uid]);
    const selected = Object.keys(selection).indexOf(line.identifier) !== -1;
    
    const [localAmount, setLocalAmount] = useState(line.amount);

    const {info, loaded, pricing, stock} = useInfo(line.identifier, uid);

    const [st, stName] = useSelector(getDefaultStock(stock));
    const [showInfo, setShowInfo] = useState(false);

    const addToOrder = () => {

        dispatch({type: 'ADD_HIGHLIGHTED', products: [line.identifier]});

        dispatch(asyncOrderLines(oid, uid, [{
            produkt_nr: line.identifier,
            amount: localAmount
        }])).then(() => dispatch({type: 'SET_PANE', pane: sideViews.ORDER}));
    };

    const handleChange = v => {

        const _line = {...line, amount: v};
        setLocalAmount(v);
        dispatch({type: 'SET_TO_WISHLIST_SELECTION', line: _line})
    };

    const isInfoOpened = useSelector(s => s.opened.wishlist === line.identifier);

    useEffect(() => {
        if (!isInfoOpened) {
            setShowInfo(false);
        }
    }, [isInfoOpened]);

    const handlePaneState = () => {
        showInfo || dispatch(openInfoPane(line.identifier, 'wishlist'));
        setShowInfo(!showInfo);
    };

    if(info === false || !loaded) {
        return null;
    }

    return (
        <li className={selected ? 'selected' : ''}>
            <Row className="no-gutters">
                
                <Col md={1} className="searchItem expand">
                    <Button color="transparent">
                        <Icon size={25} icon={showInfo ? chevronUp : chevronDown} onClick={handlePaneState}/>
                    </Button>
                </Col>
           
                <Col md={6} className="searchItem info" onClick={e =>
                    dispatch({type: selected ? 'REMOVE_FROM_WISHLIST_SELECTION' : 'SET_TO_WISHLIST_SELECTION', line})}>
                    <strong>{info.name}</strong><br/>
                    {line.identifier}
                    <br />
                    
                    
                </Col>
                {/*<Col md={3} className="searchItem">
                    
                </Col>*/}
                <Col md={3} className="searchItem">
                    <strong><Nf number={pricing.price}/></strong><br/>
                    <span className="stock">{stName}: {st} </span>
                    <div className="itemAmount">
                        <InputWithButton key={localAmount + '--' + line.id + '==' + line.identifier} amount={localAmount} handleChange={handleChange} disabled={!loaded} />
                    </div>
                </Col>
                <Col md={2} className="text-right searchItem add">
                    <Button color="primary" onClick={addToOrder} disabled={!loaded}><Icon icon={shoppingCart}/></Button>
                </Col>
            </Row>
            {showInfo && <OrderInfo name={info.produkt_navn} ProdNo={line.identifier}/>}
        </li>
    );

};