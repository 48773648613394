import React, {useEffect} from 'react';
import {useSelection} from './hooks/useSelection';
import {useDispatch, useSelector} from "react-redux";
import {actions} from "./reducers/configureStore";

const SelectableList = ({name, data, comp, id}) => {

    const ListItem = comp;

    const {isSelected, toSelection, fromSelection, clearSelection, isEmpty} = useSelection(name);
    const action = useSelector(state => state.action);

    const handleSelectAll = () => {

        dispatch({type: 'SET_ACTION', action: actions.NONE});
        if (isEmpty()) {
            data.map(line => toSelection(id(line), line));
        } else {
            clearSelection();
        }

    };

    const dispatch = useDispatch();


    useEffect(() => {
            action === actions.SELECT_ALL && handleSelectAll();
    }, [action, dispatch]);


    const toggle = line => {

        const _id = id(line);
        isSelected(_id) ? fromSelection(_id) : toSelection(_id, line);

    };

    const clear = () => clearSelection();

    return (
        <>
            {data.map(item => <ListItem item={item}
                                        key={id(item)}
                                        selected={isSelected}
                                        toggle={toggle}
                                        clear={clear}/>)}
        </>
    );
};

export default SelectableList;