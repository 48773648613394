import React, {useEffect} from 'react';
import {Header} from "../Header";
import {useParams, useRouter} from "../hooks/routerHooks";
import {useDispatch, useSelector} from "react-redux";
import {actions, searchModes} from "../reducers/configureStore";
import {Button, ButtonGroup, Col, Row} from "reactstrap";
import {asyncDeleteWishList, asyncWishLists} from "../reducers/loginActions";
import {ToolboxInfo} from "../ToolboxInfo";
import {Icon} from "react-icons-kit";
import {
    trash,
    plusSquare,
    checkSquare,
    square,
    settings
} from "react-icons-kit/feather";
import SelectableList from "../SelectableList";
import {useSelection} from "../hooks/useSelection";
import {Link} from "react-router-dom";
import {ButtonWithConfirmation} from "../OrderFooter";

const SELECTION_KEY = 'we';

const WishListTableItem = ({item, toggle, selected}) => {

    const is = selected(item.id);
    const {uid} = useParams();

    return (
        
        <li className={is ? 'selected' : ''}>
            <div className="listItemBox">
                <Row>
                    <Col md={1} className={"ListItemInfo itemSelect text-center"} onClick={() => toggle(item)}>
                        <Button to={`/wishlists/${uid}/edit/${item.id}`} className="btn btn-outline-success">
                            <Icon icon={is ? checkSquare : square}/>
                        </Button>
                    </Col>
                    <Col md={6} className="ListItemInfo itemName">
                        <h5>
                            <Link to={`/wishlists/${uid}/edit/${item.id}`} >
                                {item.name}
                            </Link>
                        </h5>
                    </Col>
                    <Col md={2} className="ListItemInfo itemEdit">{(item.lines || []).length}</Col>
                    <Col md={3} className={"ListItemInfo itemEdit text-right"}>
                        <Link to={`/wishlists/${uid}/edit/${item.id}`} className="btn btn-outline-primary">
                            <Icon icon={settings}/>
                        </Link>
                    </Col>
                </Row>
            </div>
        </li>
       
    );
};

const WishListTable = ({lists}) => {

    return (
        <div className="main-pane container-fluid">
            <Row>
                <Col md={12} className={"list-pane largeview"}>
                <div className="listHeader"><h4>Salgslister</h4></div>
                    <ul>
                        <SelectableList
                            name={SELECTION_KEY}
                            data={lists}
                            comp={WishListTableItem}
                            id={line => line.id}
                        />
                    </ul>
                </Col>
            </Row>
        </div>
    );
};

export const WishListListToolbox = () => {

    const disabled = useSelection(SELECTION_KEY).isEmpty();
    const selected = useSelection(SELECTION_KEY).selected;
    const clearSelection = useSelection(SELECTION_KEY).clearSelection;
    const dispatch = useDispatch();
    const {match: {params: {uid}}} = useRouter();

    const deleteWishLists = () => {
        return Promise.all(Object.keys(selected()).map(k => dispatch(asyncDeleteWishList(uid, k))))
                      .then(() => {
                          dispatch(asyncWishLists(uid));
                          clearSelection();
                      });

    };

    return (
        <div className="main-container container-fluid">
            <Row>
                <ToolboxInfo md={6}/>
                <Col md={6} className="text-right toolBoxRight companyMenu">
                    <ButtonGroup>
                        <Link to={`/wishlists/${uid}/edit/0`} className="btn btn-primary">
                            <Icon icon={plusSquare}/> Ny liste
                        </Link>
                        <ButtonWithConfirmation color="danger" disabled={disabled} handle={deleteWishLists}>
                            <Icon icon={trash}/> Fjern
                        </ButtonWithConfirmation>
                        <Button color="secondary"
                                onClick={e => dispatch({type: 'SET_ACTION', action: actions.SELECT_ALL})}>
                            <Icon icon={plusSquare}/>
                        </Button>
                    </ButtonGroup>
                </Col>

            </Row>
        </div>

    );
};

const WishListList = () => {

    const {match: {params: {uid}}} = useRouter();
    const [wishlists, wishlistsLoaded] = useSelector(s => [s.wishlists, s.wishlistsLoaded]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({type: 'SET_SEARCH_MODE', mode: searchModes.PRODUCT});

        if (!wishlistsLoaded) {
            dispatch(asyncWishLists(uid));
        }

    }, [dispatch, wishlistsLoaded, uid]);

    // dispatch({type: 'SET_SEARCH_MODE', mode: searchModes.PRODUCT});

    return (
        <>
            <Header/>
            <WishListListToolbox/>
            <WishListTable lists={wishlists}/>
            {/*<MainPane component={<OrdersTable orders={orders} uid={uid}/>}/>*/}
        </>

    );
};

export default WishListList;