import React, {useState, useEffect} from 'react';
import {Col, FormGroup} from "reactstrap";
import Input from "reactstrap/es/Input";
import {asyncOrderLineComment} from "./reducers/loginActions";
import {useDispatch, useSelector} from "react-redux";

export function OrderComments({ordercomment, internalComment, setIsSaving, saving, line}) {

    const dispatch = useDispatch();

    const [oid, uid] = useSelector(s => [s.orderId, s.uid]);

    const [data, setEventData] = useState({
        ordercomment: ordercomment || '',
        internalComment: internalComment || '',
        synonymList: ''
    });

    useEffect(() => {


        if (saving.comments === true) {

            if (data.ordercomment !== ordercomment || internalComment !== data.internalComment) {
                dispatch(asyncOrderLineComment(oid, uid, data, line.ordrelinje_nr))
                    .then(() => setIsSaving({...saving, comments: false}));
            } else {
                setIsSaving({...saving, comments: false})
            }
        }

    }, [saving.comments]);


    function setData(e) {
        setEventData({...data, [e.target.name]: e.target.value});
    }


    return (
        <>
            <Col className="itemComments">
                <FormGroup row>
                    <Col md={6} className="orderComment">
                        <label htmlFor="orderlineComment">Ordrelinjekommentar</label>
                        <Input type="textarea"
                               value={data.ordercomment}
                               onChange={setData}
                               name="ordercomment"
                               id="orderlineComment"
                               placeholder="Ordrelinjekommentar"
                        />
                    </Col>
                    <Col md={6} className="internalComment">
                        <label htmlFor="internalComment">Intern kommentar</label>
                        <Input
                            type="textarea"
                            value={data.internalComment}
                            onChange={setData}
                            name="internalComment"
                            id="internalComment"
                            placeholder="Internkommentar"
                        />
                    </Col>
                    {/*
                    <Col md={12} className="synonymList">
                        <label htmlFor="synonymList">Synonymer</label>
                        <Input
                            type="textarea"
                            value={data.synonymlist}
                            onChange={setData}
                            name="synonymlist"
                            id="synonymList"
                            placeholder="Synonymer"
                        />
                    </Col>
*/}
                </FormGroup>
            </Col>
            {/*
            <Col>
                <FormGroup row>
                    <Col md={12} className="synonymListSave text-right">
                        <Button color="success">
                            <Icon icon={save}/> Lagre

                        </Button>
                    </Col>
                </FormGroup>
            </Col>
*/}
        </>
    );
}
