import React, {useState, useEffect, useRef} from 'react';
import {Button, Col, Input, Row} from "reactstrap";
import {sideViews} from "./reducers/configureStore";
import {Icon} from "react-icons-kit";
import {chevronDown, chevronUp, edit, save} from "react-icons-kit/feather";
import InputWithButton from "./InputWithButton";
import {OrderComments} from "./OrderComments";
import {OrderInfo} from "./OrderInfo";
import {useDispatch, useSelector} from "react-redux";
import {asyncOrderLineDiscount, asyncOrderLines} from "./reducers/loginActions";
import {openInfoPane} from "./reducers/selectors";
import Nf from "./NF";
import Slider from 'react-rangeslider';

import 'react-rangeslider/lib/index.css';
import Label from 'reactstrap/lib/Label';
import {useStock} from "./hooks/useStock";

export const round = v => Math.round(parseInt(v * 10000, 10)) / 10000;

const OrderCommentsAndDiscounts = ({line, ordercomment, internalComment}) => {

    const [saving, setIsSaving] = useState({comments: false, discounts: false});

    return (
        <>
            <DiscountsChanger line={line} saving={saving} setIsSaving={setIsSaving}/>
            <OrderComments line={line}
                           ordercomment={ordercomment}
                           internalComment={internalComment}
                           saving={saving}
                           setIsSaving={setIsSaving}/>
            <Col className="orderLineAction">
                <Row>
                    <Col md={8}/>
                    <Col md={4} className=" text-right">
                        <Button color={"success"}
                                disabled={saving.comments || saving.discounts}
                                block
                                onClick={e => setIsSaving({discounts: true, comments: true})}
                        >
                            <Icon icon={save}/>
                            Lagre
                        </Button>
                    </Col>
                </Row>
            </Col>
        </>
    );
};

const DiscountsChanger = ({line, saving, setIsSaving}) => {


    const dispatch = useDispatch();
    const uid = useSelector(s => s.uid);

    const [val, setVal] = useState({
        discount: round(line.levertantall),
        sum: round(line.linje_sum),
        one: round(line.antall * round(line.produkt_pris_2) / 100)
    });

    const handlePercent = value => {

        const discount = round(value || 0, 10);
        const sum = round(val.one * (100 - discount));
        setVal({...val, sum, discount});
    };

    const handleValue = value => {

        const sum = parseFloat(value || 0);
        const discount = 100 - round(value / val.one); // 100-300/468*100
        setVal({...val, sum, discount});
    };

    useEffect(() => {

        console.log(val);

        if (saving.discounts === true) {

            const discount = parseFloat(val.discount);

            if (discount !== parseFloat(line.pricing.rabatt)) {
                dispatch(asyncOrderLineDiscount(line.ordre_nr, uid, val.discount, line.ordrelinje_nr))
                    .then(() => setIsSaving({...saving, discounts: false}));
            }

        }

    }, [saving.discounts, dispatch, line.pricing.rabatt]);


    return (
        <Col className="itemDiscountPane">
            <Row>
                <Col md={6} className="sliderContainer">
                    <div className="slider row no-gutters">
                        <Col md={12}>
                            <Label>Ordrelinje rabatt (%):</Label>
                        </Col>
                        <Col md={9}>
                            <Slider min={0} max={100} value={val.discount} onChange={handlePercent}/>
                        </Col>
                        <Col md={3}>
                            <Input className="itemDiscountPercent" value={val.discount}
                                   onChange={e => handlePercent(e.target.value)}/> <strong>%</strong>
                        </Col>
                    </div>
                    {/*<div>{val.discount}%</div>*/}
                </Col>
                <Col md={6}>
                    <Label>Ordrelinje sum:</Label><br/>
                    <strong>Kr.</strong> <Input className={"itemDiscountPrice"} value={val.sum}
                                                onChange={e => handleValue(e.target.value)}/>
                </Col>
            </Row>
        </Col>
    );
};

export const OrderLine = ({line}) => {

    const [selection, pane, uid, oid] = useSelector(s => [s.orderSelection, s.pane, s.uid, s.orderId]);
    const orderLines = useSelector(s => s.order);
    const orderHighlighted = useSelector(s => s.orderHighlighted);
    const dispatch = useDispatch();

    const selected = Object.keys(selection).indexOf(line.produkt_nr) !== -1;

    const states = {
        CLOSED: 'CLOSED',
        PRODUCT: 'PRODUCT',
        COMMENTS: 'COMMENTS',
    };

    const [s, setS] = useState(states.CLOSED);

    const comments = orderLines.filter(l => line.ordrelinje_nr === l.best_referanse).map(l => l.erp_product_name).join(" ");

    const isNew = orderHighlighted.indexOf(line.produkt_nr) !== -1;

    useEffect(() => {

        if (isNew) {
            const t = setTimeout(() => dispatch({type: 'REMOVE_HIGHLIGHTED', products: [line.produkt_nr]}), 1000);

            return () => clearTimeout(t);
        }

    }, [isNew, dispatch, line.produkt_nr]);

    const isInfoOpened = useSelector(s => s.opened.order === line.produkt_nr);

    const {defaultWarehouse, defaultStock} = useStock({stock: line.stock});

    useEffect(() => {
        if (!isInfoOpened) {
            setS(states.CLOSED);
        }
    }, [isInfoOpened, states.CLOSED]);

    const trigger = () => {
        dispatch({type: selected ? 'REMOVE_FROM_ORDER_SELECTION' : 'SET_TO_ORDER_SELECTION', line});
    };

    const timeout = useRef(null);

    const handleValueChange = value => {

        if (value === line.antall) {
            return;
        }

        line.antall = value;

        if (timeout.current) {
            clearTimeout(timeout.current);
        }

        timeout.current = setTimeout(() => {
            dispatch(asyncOrderLines(oid, uid, [{
                produkt_nr: line.produkt_nr,
                amount: line.antall || 1,
                ol: line.ordrelinje_nr,
                method: 'set'
            }], false));
        }, 500);

    };

    const handlePaneState = type => e => {
        s === states.CLOSED && dispatch(openInfoPane(line.produkt_nr, 'order'));
        setS(s === type ? states.CLOSED : type);
    };

    const brutto = round(line.pricing.pricingLine);

    let hasComment = comments.length > 0;
    let hasInternalComment = line.internalComment && line.internalComment.length > 0;
    let hasBothComments = hasComment && hasInternalComment;

    return (
        <li className={`order-line ${selected ? 'selected' : 'unselected'} ${pane.toLowerCase()} ${isNew ? ' new' : ''}`}>
            <Row className="no-gutters">

                {
                    pane === sideViews.ORDER &&
                    <>

                        <Col md={1} className="orderLineBtn orderLineExpandBtn text-center">
                            <Button id="caret" color="transparent" onClick={handlePaneState(states.PRODUCT)}>
                                <Icon size={28} icon={s === states.PRODUCT ? chevronUp : chevronDown}/>
                            </Button>
                        </Col>
                        <Col md={4} className="orderLineInfo text" onClick={trigger}>
                            <strong>{line.produkt_navn}</strong><br/>
                            {line.produkt_nr} <span className="stock">({defaultWarehouse}: {defaultStock})</span>
                        </Col>
                        <Col md={2} className="orderLineInfo">

                            <div className="itemInfo itemAmount">
                                <Row noGutters>
                                    <Col>
                                        <InputWithButton key={`${line.antall}-${line.ordrelinje_nr}`}
                                                         amount={line.antall}
                                                         handleChange={handleValueChange}/>
                                    </Col>
                                </Row>
                            </div>
                            <div className="itemInfo itemCode"><Row noGutters><Col md={4}>Kode:</Col><Col
                                md={8} className="text-right">{line.pricing.code}</Col></Row>
                            </div>

                        </Col>
                        <Col md={2} className="orderLineInfo">
                            <div className="itemInfo itemNet"><Row noGutters><Col md={4}>Net:</Col>
                                <Col md={8} className="text-right">
                                    <Nf number={line.pricing.pris_ordinar}/>
                                </Col>
                            </Row>
                            </div>
                            <div className="itemInfo itemBrt"><Row noGutters><Col md={4}>Brt:</Col>
                                <Col md={8} className="text-right">
                                    <Nf number={brutto}/>
                                </Col>
                            </Row>
                            </div>
                        </Col>
                        <Col md={2} className="orderLineInfo">
                            <div className="itemInfo itemRab">
                                <Row noGutters>
                                    <Col md={4}>Rab:</Col>
                                    <Col md={8} className="text-right">
                                        {line.levertantall}{line.pricing.rabattEnhet}
                                        {line.pakkseddelnr > 0 && <span className="rab-ii"> ({line.pakkseddelnr}%)</span>}
                                    </Col>
                                </Row>
                            </div>
                            <div className="itemInfo itemSum">
                                <Row noGutters>
                                    <Col md={4}>Sum:</Col>
                                    <Col md={8} className="text-right">
                                        <span className="noWrap"><Nf number={line.linje_sum}/></span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={1} className="orderLineBtn orderLineCommentBtn text-center">

                            <Button color="success" outline
                                    onClick={handlePaneState(states.COMMENTS)}>
                                <Icon size={20} icon={edit}/>
                            </Button>
                            {hasBothComments && <>
                                <div className="commentDot internal"/>
                                <div className="commentDot order"/>
                            </>}
                            {(hasComment && !hasBothComments) && <div className="commentDot order"/>}
                            {(hasInternalComment && !hasBothComments) && <div className="commentDot internal"/>}
                        </Col>

                    </>
                }

                {
                    pane !== sideViews.ORDER &&
                    <>
                        <Col md={7} className="orderLineInfo" onClick={trigger}>
                            <strong>{line.produkt_navn}</strong><br/>
                            {line.produkt_nr}
                        </Col>
                        <Col md={5} className="orderLineInfo">
                            <div className="itemInfo itemAmount">
                                <Row>
                                    <Col>
                                        <InputWithButton key={`${line.antall}-${line.ordrelinje_nr}`}
                                                         handleChange={handleValueChange}
                                                         amount={line.antall}/>
                                    </Col>
                                </Row>
                            </div>
                            <div className="itemInfo itemSum">
                                <Row>
                                    <Col md={4}>Sum:</Col>
                                    <Col md={8} className="text-right"><span className="noWrap">{line.linje_sum}</span></Col>
                                </Row>
                            </div>
                        </Col>


                    </>
                }


            </Row>

            {
                s === states.COMMENTS && pane === sideViews.ORDER &&
                <OrderCommentsAndDiscounts
                    line={line}
                    internalComment={line.internalComment}
                    ordercomment={line.externalComment}
                />
            }
            {
                s === states.PRODUCT && pane === sideViews.ORDER &&
                <OrderInfo ProdNo={line.produkt_nr} name={line.produkt_navn}/>
            }
        </li>
    )
};