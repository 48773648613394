import {warehouses} from "../api/warehouses";

export const isOrderContainsHaster = state => state.order.reduce((acc, line) => line.produkt_nr === 'H' || acc, false);

export const openInfoPane = (identifier, pane) => ({type: 'OPEN_INFO_PANE', identifier, pane});
export const isInfoOpened = (identifier, type) => state => {
    return state.opened[type] === identifier;
};

export const setReduxQuery = query => ({type: 'SET_QUERY', query});


export const getDefaultStock = stock => state => {
    const wh = state.user.DATA_NUM_10 || '1';
    const defaultWarehouse = warehouses[wh][0];


    const amount = stock.find(k => k.warehouse === wh);

    return [amount ? amount.amount : '-', defaultWarehouse];
};




