import React, {useEffect} from 'react';
import {Col, Row} from "reactstrap";

import {useDispatch, useSelector} from "react-redux";
import {asyncOrderFetch} from "./reducers/loginActions";
import {searchModes, sideViews} from "./reducers/configureStore";

import {Header} from "./Header";
import MainPane from "./MainPane";
import {useRouter} from "./hooks/routerHooks";
import {Toolbox} from "./Toolbox";
import {OrderFooter} from "./OrderFooter";
import {OrderLine} from "./OrderLine";
import {setReduxQuery} from "./reducers/selectors";

export const orderFilter = line => ['T', 'H', 'I', 'D'].indexOf(line.produkt_nr) === -1;

export const UserLine = ({user}) => {

    const {history} = useRouter();
    const dispatch = useDispatch();

    const address    = (user.DATA_ALPH_5 || user.besoks_adresse) + '';
    const department = user.department + '';
    const click = () => {
        dispatch(setReduxQuery(''));
        dispatch({type: 'SET_PANE', pane: sideViews.ORDER});
        history.push(`/user/${user.kontakt_nr}`);
    };

    return (

        <li>
            <Row noGutters>
                <Col md={2}>
                    <div className="contactBox clearfix" onClick={click}>
                        {user.kontakt_nr}
                    </div>
                </Col>
                <Col md={10} onClick={click}>
                    <strong>{user.firmanavn}</strong><br/>
                    {address}{(address.length > 0 && department.length > 0) && (` / `)}{department}
                </Col>
            </Row>
        </li>

    )
};

function App() {

    const dispatch = useDispatch();

    const {match: {params: {uid, oid}}} = useRouter();
    const [pane, order] = useSelector(state => [state.pane, state.order]);

    useEffect(() => {

        dispatch({type: 'SET_CURRENT_ORDER_EDITED', currentOrderEdited: {oid, uid}});
        dispatch({type: 'SET_UID', uid});
        dispatch(asyncOrderFetch(oid, uid));
        dispatch({type: 'SET_SEARCH_MODE', mode: searchModes.PRODUCT});

    }, [oid, uid, dispatch]);

    return (
        <>

            <Header/>
            <Toolbox/>

            <MainPane component={<Col className={pane === sideViews.ORDER ? 'order-pane largeview' : 'order-pane minified'} 
                md={pane === sideViews.ORDER ? 12 : 5}>
                <ul>
                    {order.filter(orderFilter).map(k => <OrderLine
                        pane={pane}
                        line={k}
                        key={`${k.ordrelinje_nr}`}
                        isNew={!!k.isNew}
                    />)}
                </ul>
            </Col>}/>
            <OrderFooter/>
        </>
    );
}

export default App;
