import React, {useState} from 'react';
import {Button} from "reactstrap";
import {Icon} from "react-icons-kit";
import {chevronDown, chevronUp} from "react-icons-kit/feather";

const ShowMore = ({text, length = 80}) => {

    const strip = html => {
        var doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const [expanded, setExpanded] = useState(false);

    const strippedText = strip(text);
    const needsTruncating = strip(text).length > length;

    let textToShow = '';

    if (needsTruncating && !expanded) {

        let truncated = false;

        textToShow = strippedText.split(' ').reduce((acc, chunk) => {

            const text = acc + ' ' + chunk;

            if (text.length > length && !truncated) {
                truncated = true;
            }

            if (truncated) {
                return acc;
            }

            return text;

        })
    } else {
        textToShow = text;
    }

    return (
        <>
            <div dangerouslySetInnerHTML={{__html: textToShow}}/>
            {
                needsTruncating &&
                <Button style={{marginLeft: -14}} color="link" onClick={() => setExpanded(!expanded)}>
                    <Icon icon={expanded ? chevronUp : chevronDown} />
                    {expanded ? 'Vis mindre' : 'Vis mer'}
                </Button>
            }
        </>
    );
};

export default ShowMore;