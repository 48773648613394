import React, {useState} from 'react';
import {Button, ButtonGroup} from "reactstrap";
import api, {inStock} from "./api";
import {Icon} from "react-icons-kit";
import {database, shoppingCart, tag, chevronLeft, chevronRight} from "react-icons-kit/feather";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useRouter} from "./hooks/routerHooks";


export const ToolboxCompanyWrapper = ({component, ...rest}) => {
    const [opened, setOpened] = useState(false);

    return (
        <ButtonGroup {...rest}>
            {opened || component}
            {opened && <ToolboxCompany/>}
            <Button color={"light"} onClick={() => setOpened(!opened)}>
                <Icon icon={opened ? chevronRight : chevronLeft}/>
            </Button>
        </ButtonGroup>
    );

};

const ToolboxCompany = () => {

    const uid = useSelector(s => s.uid);
    const {history} = useRouter();

    return (
        <ButtonGroup>
            <Button color="success" onClick={e => {
                e.preventDefault();
                api.new(uid).then(({data: {order}}) => {
                    inStock(true);
                    history.push(`/orders/${uid}/edit/${order}`)
                });
            }}>
                <Icon icon={shoppingCart}/> Ny
            </Button>
            <Link to={`/user/${uid}`} className="btn btn-primary">
                <Icon icon={tag}/> Parkert
            </Link>
            <Link to={`/history/${uid}`} className="btn btn-warning">
                <Icon icon={database}/> Historikk
            </Link>
            <Link to={`/wishlists/${uid}`} className="btn btn-primary">
                <Icon icon={tag}/> Lister
            </Link>
        </ButtonGroup>
    );
};

export default ToolboxCompany;

