export const leveringsMaater = {
    '1'  : 'Egen  bil Trondheim',
    '2'  : 'Hardanger Ekspessen',
    '3'  : 'Iversen Transport',
    '4'  : 'Tollpost',
    '5'  : 'Schenker',
    '6'  : 'I løpet av dagen',
    '7'  : 'Bring Logistics',
    '8'  : 'Bring Express',
    '9'  : 'Fritt levert v/netto 1600,-',
    '10' : 'Fagerheim',
    '11' : 'Hentet',
    '12' : 'Klimanøytral Servicepakke',
    '13' : 'Bedriftspostpakke',
    '14' : 'Leveres',
    '15' : 'Med andre varer',
    '16' : 'Ved første anledning',
    '17' : 'Fast frakt TP kr 120,-',
    '18' : 'Fritt TP over 3000,- netto',
    '19' : 'Brev',
    '20' : 'PostNord',
    '21' : 'Transportvikaren',
    '22' : 'Fraktfritt over 200 ltr',
    '23' : 'Fritt levert',
    '24' : 'Jekteviken',
    '25' : 'Voss Transportservice TLF 56533016',
    '27' : 'A-Trans',
    '28' : 'Schibsted Distribusjon Vest AS',
    '29' : 'Knuts Transport',
    '30' : 'William & Bernt`s',
    '31' : 'Ingruns Varetaxi',
    '32' : 'Trollheimsexpressen',
    '33' : 'Walthers Transport',
    '34' : 'Suldal Transport',
    '35' : 'Erik Iversen Transport',
    '36' : 'Vognmannen Transport',
    '37' : 'Godstrafikk og Bilspedisjon AS',
    '38' : 'Sagøy Varetaxi',
    '39' : 'Kvisvik Varetaxi',
    '40' : 'Hardanger Expressen',
    '50' : 'Tur kl 08.15',
    '51' : 'Tur kl 08.30',
    '52' : 'Tur kl 08.45',
    '53' : 'Tur kl 09.45',
    '54' : 'Tur kl 10.00',
    '55' : 'Tur kl 11.00',
    '56' : 'Tur kl 11.30',
    '57' : 'Tur kl 12.00',
    '58' : 'Tur kl 12.30',
    '59' : 'Tur kl 13.00',
    '60' : 'Tur kl 13.30',
    '61' : 'Tur kl 14.00',
    '62' : 'Åsvik Transport',
    '63' : 'System Transport (Tidl.Rasken Transport 91834412)',
    '64' : 'R.Lund Transport AS',
    '65' : 'Fraktfritt over 300 ltr',
    '66' : 'Fraktfritt over 500 ltr',
    '67' : 'Lie Prosjektering  - Vi ringer Lie Tel.nr. 906 04 098',
    '68' : 'Torilds Transport',
    '69' : 'Leirvik Varetaxi',
    '70' : 'Innhenting andre lev.',
    '71' : '09:00 Sandnes-Forus-Sola',
    '72' : '09:00 Sandnes-Forus-Stavanger-Sola',
    '73' : 'Tur 2 - Stavanger',
    '74' : 'Tur 2 - Jæren',
    '75' : '11:30 Sandnes-Forus-Sola-Stavanger',
    '76' : 'Karmøy Pakketransport',
    '77' : 'Hentes'
};