import React, {useState, useEffect} from 'react';
import {ButtonGroup, Input, Modal, ModalBody, Button, FormGroup, Row, Table, Col} from "reactstrap";

import {Icon} from "react-icons-kit";
import {cpu, skipBack, checkCircle, clock, alertCircle} from "react-icons-kit/feather";
import {fetchProduct} from "./hooks/useInfo";
import {useParams} from "./hooks/routerHooks";
import {LabelsDropdown, WishlistsDropdown} from "./Toolbox";

const ScannerLine = ({line}) => {

    const statuses = {
        FIXED: [checkCircle, 'success'],
        NONE: [clock, 'warning'],
        NOPE: [alertCircle, 'danger']
    };

    const [icon, color] = statuses[line.status];

    return (
        <tr>
            <td><Icon className={`text-${color}`} icon={icon}/></td>
            <td>{line.ProdNo}</td>
            <td>{line.Descr || ''}</td>
        </tr>
    );
};

const Scanner = ({importerFunc}) => {

    const states = {
        TEXTAREA: 'TEXTAREA',
        PROCESSOR: 'PROCESSOR',
        PROCESSING: 'PROCESSING'
    };

    const {uid} = useParams();

    const [state, setState] = useState(states.TEXTAREA);
    const [value, setValue] = useState({});

    const handleChange = e => {
        const nos = e.target.value.split("\n");
        const val = {};

        nos.map(no => val[no] = !!value[no] ? value[no] : {status: 'NONE', ProdNo: no});

        setValue(val);
    };


    const handleProcess = () => {

        setState(states.PROCESSING);

        Promise.all(Object.keys(value).map(v => {
            if (value[v].status === 'NONE') {
                const p = fetchProduct(v, uid);

                p.then(({info}) => {
                    if (info) {
                        value[v] = {...info, status: 'FIXED'};
                    } else {
                        value[v] = {status: 'NOPE', ProdNo: v};
                    }

                    setValue({...value})
                });

                return p;
            }
        })).then(() => {
            setValue({...value});
            setState(states.PROCESSOR);
        });

    };

    const [style, sStyle] = useState({
        border: '1px solid aliceblue',
        backgroundColor: 'aliceblue',
        height: 150
    });

    useEffect(() => {
        const lines = Object.keys(value).length;
        sStyle({...style, ...{height: (lines + 1) * 25 + 5}});
    }, [value]);


    const hasValues = Object.keys(value).length > 0;
    const wishListProducts = Object.keys(value).map(k => ({produkt_nr: k}));

    console.log(Object.keys(value));

    return (
        <div>
            <FormGroup className="scanner-results">
                {state === states.TEXTAREA &&
                <Input type="textarea" value={Object.keys(value).join("\n")} onChange={handleChange} style={style}/>}
                {(state === states.PROCESSOR || state === states.PROCESSING) &&
                <Row noGutters style={style}>
                    <Table borderless size="sm">
                        <tbody>
                        {Object.keys(value).map(v => <ScannerLine line={value[v]} key={v}/>)}
                        </tbody>
                    </Table>
                </Row>}
            </FormGroup>
            <FormGroup row>
                <Col md={6}>
                    <ButtonGroup>
                        {state === states.TEXTAREA && (
                            <Button color="success" onClick={handleProcess}>
                                <Icon icon={cpu}/>
                                Proccess
                            </Button>
                        )}
                        {state === states.PROCESSOR && (
                            <>
                                <Button color="warning" onClick={e => setState(states.TEXTAREA)}>
                                    <Icon icon={skipBack}/> Reset
                                </Button>
                                {importerFunc && (
                                    <Button color="success" onClick={e => importerFunc(Object.keys(value))}>
                                        <Icon icon={checkCircle}/> Import
                                    </Button>
                                )}
                            </>
                        )}
                    </ButtonGroup>
                </Col>
                <Col md={6} className="text-right">
                    <ButtonGroup>
                        <LabelsDropdown selection={value}
                                        disabled={!hasValues}
                        />
                        <WishlistsDropdown disabled={!hasValues}
                                           lines={wishListProducts}
                        />
                    </ButtonGroup>
                </Col>
            </FormGroup>
        </div>
    );
};

export default Scanner;


export const ScannerModal = ({modal, setModal, ...rest}) => {

    return (
        <Modal size="lg" isOpen={modal} toggle={() => setModal(!modal)}>
            <ModalBody>
                <Scanner {...rest} />
            </ModalBody>
        </Modal>
    );
};

