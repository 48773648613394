import {useState, useEffect} from 'react';
import api from "../api";

export const relPath = image => `//lakk.imgix.net/${image.replace(/\/media\/bilder/, '')}`;

const productCache = {};

function isCached(product, user) {

    if (!productCache[user]) {
        productCache[user] = {};

        return false;
    }

    return !!productCache[user][product];
}

export function fetchProduct(product, user) {

    return new Promise(resolve => {
        if (isCached(product, user)) {
            return resolve(productCache[user][product]);
        } else {
            api.images(product, user).then(({data}) => {
                productCache[user][product] = {...data, loaded: true};
                return resolve(productCache[user][product]);
            })
        }
    });

}

export function useInfo(product, user) {

    const [info, setInfo] = useState({images: [], files: [], info: {}, stock: [], pricing: {}, loaded: false, tags: []});

    useEffect(() => {

        fetchProduct(product, user).then(info => setInfo(info));

    }, [product, user]);

    return info;
}
