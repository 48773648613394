import React, {useState, useEffect, useRef} from 'react';
import {useParams, useRouter} from "./hooks/routerHooks";
import api from './api';
import {Header} from "./Header";
import {BasicToolbox, LabelsDropdown} from "./Toolbox";
import MainPane from "./MainPane";
import {useDispatch, useSelector} from "react-redux";
import {sideViews} from "./reducers/configureStore";
import {Button, ButtonGroup, Col, Input, InputGroup, InputGroupAddon, Row} from "reactstrap";
import {useInfo} from "./hooks/useInfo";
import {openInfoPane} from "./reducers/selectors";
import {
    asyncAddToWishList,
    asyncDeleteFromWishList, asyncDeleteWishList,
    asyncNotify,
    asyncWishLists
} from "./reducers/loginActions";
import {Icon} from "react-icons-kit";
import {
    chevronDown,
    chevronUp,
    edit2,
    save,
    trash,
    chevronLeft,
    square,
    checkSquare,
    user,
    users,
    globe, cpu
} from "react-icons-kit/feather";
import InputWithButton from "./InputWithButton";
import {OrderInfo} from "./OrderInfo";
import SelectableList from "./SelectableList";
import {useSelection} from "./hooks/useSelection";
import {ButtonWithConfirmation} from "./OrderFooter";
import {Link} from "react-router-dom";
import {ScannerModal} from "./Scanner";


export const WishListLineLine = ({item, selected, toggle, clear, toggleAll}) => {

    const uid = useSelector(s => s.uid);
    const isSelected = selected(item.identifier);
    const pane = useSelector(state => state.pane);
    const dispatch = useDispatch();

    const [localAmount] = useState(item.amount);

    const {info, loaded} = useInfo(item.identifier, uid);

    const [showInfo, setShowInfo] = useState(false);

    const timeout = useRef(null);

    const handleValueChange = value => {

        if (value === item.amount) {
            return;
        }

        item.amount = value;

        if (timeout.current) {
            clearTimeout(timeout.current);
        }

        timeout.current = setTimeout(() => {
            api.wl(uid, item.wishlist).line(item).edit().then(() => {
                dispatch(asyncNotify('Changes has been made', 'success'));

                dispatch({type: 'SET_WISHLISTS_LOADED', wishlistsLoaded: false});
                dispatch(asyncWishLists(uid));

                clear();
            });
        }, 1000);

    };

    const isInfoOpened = useSelector(s => s.opened.wishlist === item.identifier);

    useEffect(() => {
        if (!isInfoOpened) {
            setShowInfo(false);
        }
    }, [isInfoOpened]);

    const handlePaneState = () => {
        showInfo || dispatch(openInfoPane(item.identifier, 'wishlist'));
        setShowInfo(!showInfo);
    };

    if (info === false || !loaded) {
        return null;
    }


    return (
        <>
            <li className={`${isSelected ? 'selected' : ''}`}>
                <div className="listItemBox">
                    <Row className="no-gutters">
                        {pane !== sideViews.SEARCH && <>
                            <Col md={1} className="searchItem expand">
                                <Button color="transparent">
                                    <Icon size={25} icon={showInfo ? chevronUp : chevronDown}
                                          onClick={handlePaneState}/>
                                </Button>
                            </Col>

                            <Col md={7} className="searchItem info" onClick={() => toggle(item)}>
                                <strong>{info.name}</strong><br/>
                                {item.identifier}
                            </Col>
                            {<Col>
                                <InputWithButton amount={localAmount} handleChange={handleValueChange}
                                                 disabled={!loaded}/>
                            </Col>}
                            {/*
                        <Col md={3} className="searchItem">
                            Pris: <strong><Nf number={pricing.price}/></strong><br/>
                            <span className="stock">{stName}: {st} </span>
                        </Col>
        */}
                            <Col md={1} className={"text-center"}>
                                <Button color="link" onClick={() => toggle(item)}>
                                    <Icon size={25} icon={selected(item.identifier) ? checkSquare : square}/>
                                </Button>
                            </Col>
                        </>}
                        {pane === sideViews.SEARCH && <>
                            <Col md={7} className="searchItem info" onClick={() => toggle(item)}>
                                <strong>{info.name}</strong><br/>
                                {item.identifier}
                            </Col>
                            {<Col>
                                <InputWithButton amount={localAmount} handleChange={handleValueChange}
                                                 disabled={!loaded}/>
                            </Col>}
                        </>}
                    </Row>

                </div>
                {showInfo && <OrderInfo name={info.produkt_navn} hideWishListButton ProdNo={item.identifier}/>}
            </li>
        </>

    );
};

const WishListSwitcher = ({list, identity}) => {

    const {uid, lid} = useParams();
    const dispatch = useDispatch();

    const s = list.seller === null ? 0 : 1;
    const u = list.user === null ? 0 : 2;
    const globalSwitch = s | u;

    const handleGlobalSwitch = type => () => {
        api.wl(uid, lid).edit(lid, {type}).then(() => {
            dispatch({type: 'SET_WISHLISTS_LOADED', wishlistsLoaded: false});
            dispatch(asyncWishLists(uid));
            dispatch({type: 'SET_WISHLIST_DIRTY', wishlistDirty: true});
        });
    };

    return (
        <>
            {(lid > 0 && (list.seller === identity || identity === '5')) && <ButtonGroup>
                <Button
                    color={globalSwitch === 3 ? 'success' : 'secondary'}
                    onClick={handleGlobalSwitch(1)}>
                    <Icon icon={user}/>
                </Button>
                {identity === '5' && <Button color={globalSwitch === 2 ? 'success' : 'secondary'}
                                             onClick={handleGlobalSwitch(2)}>
                    <Icon icon={users}/>
                </Button>
                }
                <Button color={globalSwitch === 0 ? 'success' : 'secondary'}
                        onClick={handleGlobalSwitch(3)}>
                    <Icon icon={globe}/>
                </Button>
            </ButtonGroup>
            }
        </>
    );
};

const WishList = () => {

    const {uid, lid} = useParams();

    const dirty = useSelector(s => s.wishlistDirty);
    const dispatch = useDispatch();

    const name = useRef('');

    const [list, setList] = useState({
        name: '...',
        user: null,
        seller: null,
        lines: []
    });

    const [identity, setIdentity] = useState(null);

    const [edit, setEdit] = useState(false);

    const pane = useSelector(state => state.pane);
    const SELECTION_KEY = `wishlist${lid}`;
    const {history} = useRouter();

    useEffect(() => {

        if (dirty) {
            api.wishlist(uid, lid).then(({data: {wishlist, _identity}}) => {
                if (wishlist) {
                    setList({
                        name: wishlist.name,
                        lines: wishlist.lines || [],
                        seller: wishlist.seller,
                        user: wishlist.user
                    });
                    name.current = wishlist.name;
                    setIdentity(_identity);
                }
                dispatch({type: 'SET_WISHLIST_DIRTY', wishlistDirty: false})
            });
        }

    }, [uid, lid, dirty, dispatch]);

    const handleNameSave = e => {

        if (lid !== '0') {
            api.wl(uid, lid).edit(lid, {name: list.name}).then(() => {
                dispatch(asyncNotify(`${list.name} var endret`, 'success'));

                dispatch({type: 'SET_WISHLISTS_LOADED', wishlistsLoaded: false});
                dispatch(asyncWishLists(uid));

                dispatch({type: 'SET_WISHLIST_DIRTY', wishlistDirty: true});

                setEdit(false);
            });
        } else {
            api.wl(uid, lid).create(lid, {name: list.name}).then(({data}) => {
                history.push(`/wishlists/${uid}/edit/${data.wishlist.id}`);
                dispatch(asyncNotify(`${list.name} var opprettet`, 'success'));
            });
        }
    };

    return (
        <div>
            <Header/>
            <BasicToolbox component={WishListToolbox}/>
            <MainPane component={<Col md={pane === sideViews.SEARCH ? 5 : 12} className="list-pane">
                {pane !== sideViews.SEARCH && (
                    <Row>
                        <Col md={8}>
                            {
                                edit ||
                                <div className="listHeader">
                                    <h4>
                                        <Link to={`/wishlists/${uid}`} className="btn btn-info">
                                            <Icon icon={chevronLeft}/>
                                        </Link>
                                        {' '}{list.name || '...'}
                                        <Button color="link" onClick={e => setEdit(true)}><Icon icon={edit2}/></Button>
                                    </h4>
                                </div>
                            }

                            {edit && (
                                <InputGroup>
                                    <Input onChange={e => setList({...list, name: e.target.value})}
                                           value={list.name || '...'}/>
                                    <InputGroupAddon addonType="append">
                                        <Button color="success" onClick={handleNameSave}>
                                            <Icon icon={save}/>
                                        </Button>
                                        <ButtonWithConfirmation color="danger" handle={() => {
                                            dispatch(asyncDeleteWishList(uid, lid)).then(() => {
                                                dispatch(asyncWishLists(uid));
                                                history.push(`/wishlists/${uid}`);
                                            });

                                        }}>
                                            <Icon icon={trash}/>
                                        </ButtonWithConfirmation>
                                    </InputGroupAddon>
                                </InputGroup>
                            )}
                        </Col>
                        <Col md={4} className="text-right">
                            <WishListSwitcher list={list} identity={identity}/>
                        </Col>
                    </Row>
                )}
                <Row className="no-gutters">
                    <Col md={12}>
                        <ul>
                            <SelectableList
                                name={SELECTION_KEY}
                                data={list.lines}
                                comp={WishListLineLine}
                                id={line => line.identifier}
                            />
                        </ul>
                    </Col>
                </Row>

            </Col>}/>
        </div>
    );
};

const WishListToolbox = () => {

    const {match: {params: {uid, lid}}} = useRouter();
    const SELECTION_KEY = `wishlist${lid}`;

    const selection = useSelection(SELECTION_KEY);
    const dispatch = useDispatch();
    const lines = Object.values(selection.selected());

    const handleDelete = () => {
        dispatch(asyncDeleteFromWishList(uid, lid, lines))
            .finally(() => {
                dispatch({type: 'SET_WISHLIST_DIRTY', wishlistDirty: true});
                selection.clearSelection();
            });
    };


    const [importer, setImporter] = useState(false);

    const importerFunc = data => {
        dispatch(asyncAddToWishList(uid, lid, data.map(product => ({product}))))
            .then(() => {
                dispatch({type: 'SET_WISHLIST_DIRTY', wishlistDirty: true});
                setImporter(false);
            });
    };


    return (
        <Col md={5} sm={5} className="toolBoxRight">
            <ButtonGroup className="float-right">
                <ButtonWithConfirmation disabled={selection.isEmpty()} handle={handleDelete}>
                    Fjern
                </ButtonWithConfirmation>
                <LabelsDropdown selection={selection.selected()} disabled={selection.isEmpty()}/>

                <Button onClick={() => setImporter(!importer)} color="warning">
                    <Icon icon={cpu}/>
                </Button>
                <ScannerModal modal={importer} setModal={setImporter} importerFunc={importerFunc}/>


            </ButtonGroup>

        </Col>
    );
};


export default WishList;