import React, {useState, useEffect} from 'react';
import api from "../api";
import {Icon} from "react-icons-kit";
import {folder, book} from "react-icons-kit/feather";
import {Badge, Button, Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import {round} from "../OrderLine";

const Campaigns = () => {

    const [campaigns, setCampaigns] = useState({});
    const [expanded, setExpanded] = useState(null);

    const toggleExpanded = key => e => setExpanded(expanded === key ? null : key);

    useEffect(() => {
        api.campaigns().then(({data: {campaigns}}) => setCampaigns(campaigns));
    }, []);

    return (
        <Row>
            <Col md={12}>
                <ListGroup>
                    {Object.keys(campaigns).map(key => (
                        <ListGroupItem key={key}>
                            <Icon icon={folder}/>
                            <Button color="link" onClick={toggleExpanded(key)}>{' '}{key}</Button>
                            {
                                expanded === key &&
                                <ListGroup>
                                    {campaigns[key].map(file => (
                                        <ListGroupItem key={file.pretty}>
                                            <Badge className="float-right" color={"success"}>
                                                {`${round(file.size / 1024)} Kb`}
                                            </Badge>
                                            <Icon icon={book}/>
                                            {' '}
                                            <a rel="noopener noreferrer"
                                               target="_blank" href={`//lakk.dna.argo.no${file.link}`}>
                                                {file.pretty}
                                            </a>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            }
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </Col>
        </Row>
    );
};

export default Campaigns;