import React, {useState, useEffect} from 'react';
import {Header} from "./Header";
import MainPane from "./MainPane";
import {useDispatch, useSelector} from "react-redux";
import {searchModes, sideViews} from "./reducers/configureStore";
import api from './api';
import {Button, Col, Row} from "reactstrap";
import {LineChart, Line, XAxis, Tooltip, ResponsiveContainer, BarChart, Bar} from 'recharts';
import Nf from "./NF";
import {Link} from "react-router-dom";
import {Icon} from "react-icons-kit";
import {shoppingCart, zapOff} from "react-icons-kit/feather";
import {useRouter} from "./hooks/routerHooks";
import {OrderStatus} from "./ParkedOrders";

const Dashboard = () => {

    const dispatch = useDispatch();

    dispatch({type: 'SET_SEARCH_MODE', mode: searchModes.USER});

    return (
        <>
            <Header/>
            <div className="main-container row"/>
            <MainPane component={<DashboardPane/>}/>
        </>
    );
};


const reduceToUser = (data, user) => {

    return Object.keys(data).map(key => {
        return {...data[key][user], name: key}
    });

};

const month = m => {
    const mm = parseInt(m, 10);
    const monthes = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov'];

    return monthes[mm] || '';
};

const DashboardPane = () => {

    const [data, setData] = useState({data: {}});
    const [latest, setLatest] = useState({lastOrders: [], lastCustomers: []});
    const [favorites, setFavs] = useState([]);

    const isSearch = useSelector(state => state.pane) === sideViews.USER;

    useEffect(() => {
        api.stats().then(({data}) => setData(data));
        api.latest().then(({data}) => setLatest(data));
        api.userFavorites(0).then(({data:{favorites}}) => setFavs(favorites));
    }, []);

    const {history} = useRouter();
    const dispatch = useDispatch();

    return (

        <Col md={isSearch ? 5 : 12} className={isSearch ? 'dash-pane largeview' : 'dash-pane minified'}>
            <Row className="no-gutters">
                <Col md={isSearch ? 12 : 6}>
                    {
                        latest.lastOrders.length > 0 &&
                        <div className="frontBox frontBox_01">
                            <div className="frontBoxHead">
                                <h5>SISTE FULLFØRTE ORDRER</h5>
                            </div>
                            <div className="frontBoxContent">
                                <ul className="container-fluid">
                                    {latest.lastOrders.map(order => (
                                        <li key={order.ordre_nr}>

                                            
                                                <div className="row no-gutters listBox clearfix">
                                                    <div className="col-md-2 col-sm-2 col-2">
                                                        <div className="dateBox clearfix">
                                                            <span className="day">{order.best_dato.split('-')[2]}</span>
                                                            <span className="month">
                                                                {month(order.best_dato.split('-')[1])}
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-7 col-sm-7 col-7 itemInfo">
                                                        <Link to={`/orders/${order.kontakt_nr}/view/${order.ordre_nr}`}>
                                                            <strong>{order.firmanavn}</strong>
                                                            <span>Ordre #{order.erp_ordreid}</span>
                                                        </Link>
                                                    </div>
                                                    <div className="col-md-3 col-sm-3 col-3 itemSum">
                                                        <span className="noWrap"><Nf number={order.sum}/></span>
                                                        <OrderStatus status={order.status} pno={order.DATA_NUM_6}/>
                                                    </div>
                                                </div>
                                            
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="frontBoxFoot">

                            </div>
                        </div>
                    }
                </Col>
                <Col md={isSearch ? 12 : 6}>
                    {
                        latest.lastCustomers.length > 0 &&
                        <div className="frontBox frontBox_04">
                            <div className="frontBoxHead">
                                <h5>SISTE KUNDER</h5>
                            </div>

                            <div className="frontBoxContent">
                                <ul>
                                    {latest.lastCustomers.map(order => (
                                        <li key={order.kontakt_nr}>
                                            <div className="row no-gutters listBox clearfix">
                                                {/*<div className="logoBox">
                                                    <img src="" alt={order.firmanavn}/>
                                                </div>*/}
                                                <div className="col-md-10 col-sm-10 col-10 itemInfo">
                                                    <Link to={`/user/${order.kontakt_nr}`}>
                                                        <strong>{order.firmanavn}</strong>
                                                    </Link>
                                                </div>
                                                <div className="col-md-2 col-sm-2 col-2 text-right itemSum">
                                                    <Button color="primary" onClick={e => {
                                                        e.preventDefault();
                                                        api.new(order.kontakt_nr)
                                                           .then(({data}) =>
                                                               history.push(`/orders/${order.kontakt_nr}/edit/${data.order}`));
                                                    }}>
                                                        <Icon icon={shoppingCart}/>
                                                    </Button>
                                                </div>
                                            </div>

                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="frontBoxFoot">

                            </div>
                        </div>
                    }
                </Col>

                {favorites.length > 0 && <Col md={isSearch ? 12 : 6}>
                        <div className="frontBox frontBox_04">
                            <div className="frontBoxHead">
                                <h5>FAVORITTLISTE</h5>
                            </div>

                            <div className="frontBoxContent">
                                <ul>
                                    {favorites.map(order => (
                                        <li key={order.kontakt_nr}>
                                            <div className="row no-gutters listBox clearfix">
                                                {/*<div className="logoBox">
                                                    <img src="" alt={order.firmanavn}/>
                                                </div>*/}
                                                <div className="col-md-10 col-sm-10 col-10 itemInfo">
                                                    <Link to={`/user/${order.kontakt_nr}`}>
                                                        <strong>{order.firmanavn}</strong>
                                                    </Link>
                                                </div>
                                                <div className="col-md-2 col-sm-2 col-2 text-right itemSum">
                                                    <Button color="primary" onClick={e => {
                                                        e.preventDefault();
                                                        api.new(order.kontakt_nr)
                                                           .then(({data}) =>
                                                               history.push(`/orders/${order.kontakt_nr}/edit/${data.order}`));
                                                    }}>
                                                        <Icon icon={shoppingCart}/>
                                                    </Button>
                                                </div>
                                            </div>

                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="frontBoxFoot">

                            </div>
                        </div>
                </Col>}
                <Col md={isSearch ? 12 : 6}>
                    <div className="frontBox frontBox_04">
                        <div className="frontBoxHead">
                            <h5>VERKTØYMENY</h5>
                        </div>

                        <div className="frontBoxContent">
                            <Button color="danger" onClick={() => api.logout().then(() => {
                                dispatch({type: 'SET_TOKEN', token: ''});
                                window.location.reload();
                            })}>
                                {'LOGG UT '}
                                <Icon icon={zapOff}/>
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            {
                data.identity && <Row>
                    {/*<Col md={isSearch ? 12 : 12}>

                        <Table striped size="sm" hover>
                            <thead>
                            <tr>
                                <th/>
                                <th>ORDERS</th>
                                <th>POSITIONS</th>
                                <th>POSITIONS TOTAL</th>
                            </tr>
                            </thead>
                            <tbody>
                            {reduceToUser(data.data, "5").map(stats => <tr key={stats.name}>
                                <td>{stats.name}</td>
                                <td>{stats.num_orders}</td>
                                <td>{stats.num_positions}</td>
                                <td>{stats.total_positions}</td>
                            </tr>)}
                            </tbody>
                        </Table>
                    </Col>*/}
                    <Col md={isSearch ? 12 : 12}>
                        <ResponsiveContainer height={300}>
                            <LineChart data={reduceToUser(data.data, data.identity.id)}>
                                <Tooltip/>
                                <Line type="monotone" dataKey="num_orders" stroke="#8884d8" activeDot={{r: 8}}/>
                                <XAxis dataKey="name"/>
                            </LineChart>
                        </ResponsiveContainer>
                    </Col>
                    <Col md={isSearch ? 12 : 12}>
                        <ResponsiveContainer height={300}>
                            <BarChart data={reduceToUser(data.data, "5")}>
                                <Tooltip/>
                                <Bar dataKey="num_orders" fill="#8884d8" label/>
                                <XAxis dataKey="name"/>
                            </BarChart>
                        </ResponsiveContainer>
                    </Col>
                </Row>
            }
        </Col>
    );
};


export default Dashboard;