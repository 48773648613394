import React from 'react';
import {Col} from "reactstrap";
import {sideViews} from "./reducers/configureStore";
import {useSelector} from "react-redux";
import {UserLine} from "./App";
import {WishListPane} from "./wishlists/WishListPane";
import {SearchLine} from "./SearchLine";

const MainPane = ({component}) => {

    const pane = useSelector(state => state.pane);


    return (
        <div className="main-pane container-fluid">
            <div className="row">
                {pane === sideViews.SEARCH && <SearchResultsPane/>}
                {pane === sideViews.WISHLIST && <WishListPane/>}
                {component}
                {pane === sideViews.USER && <UserSearchResultsPane/>}
            </div>
        </div>
    );
};

function SearchResultsPane() {

    const search = useSelector(s => s.searchResults);
    const eanQuery = useSelector(s => s.eanQuery);
    const query = useSelector(s => s.query);
    const order = useSelector(s => s.order);

    return (
        <Col className="search-results-pane" md={7}>
            <ul>
                {search.map(product => <SearchLine
                    key={product.produkt_nr}
                    product={product}
                    order={order}
                />)}
                {(eanQuery && search.length === 0) && (
                    <li>
                        <h4 className="text-danger">
                            {query} ser ut til å være en gyldig strekkode men det finnes ikke tilknyttet produkt.
                        </h4>
                    </li>
                )}
            </ul>

        </Col>
    );
}

function UserSearchResultsPane() {

    const userResults = useSelector(s => s.userResults);

    return (
        <Col className="user-search-results-pane" md={7}>
            <ul>
                {userResults.map(user => <UserLine
                    key={user.kontakt_nr}
                    user={user}
                />)}
            </ul>
        </Col>
    );
}

export default MainPane;