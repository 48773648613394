import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Button,
    ButtonDropdown,
    ButtonGroup,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input, InputGroup, InputGroupAddon,
    Row
} from "reactstrap";
import {actions, sideViews} from "./reducers/configureStore";
import {FromWishList, ToolboxInfo} from "./ToolboxInfo";
import {Icon} from "react-icons-kit";
import {chevronLeft, maximize, shoppingCart, tag, trash2, save, cpu} from "react-icons-kit/feather";

import api from './api';
import {
    asyncAddToWishList,
    asyncNotify,
    asyncOrderLines,
    asyncOrderLinesDelete,
    asyncWishLists
} from "./reducers/loginActions";
import {ToolboxWishList} from "./wishlists/ToolboxWishList";
import Notification from "./Notification";
import {useParams} from "./hooks/routerHooks";
import {setReduxQuery} from "./reducers/selectors";
import {ScannerModal} from "./Scanner";
import {ToolboxCompanyWrapper} from "./ToolboxCompany";


export const ToolboxSearch = () => {

    const dispatch = useDispatch();

    const [orderId, uid] = useSelector(state => [state.orderId, state.uid]);
    const action = useSelector(state => state.action);

    const selection = useSelector(s => s.searchSelection);
    const disabled = Object.keys(selection).length === 0;

    const {lid} = useParams();

    function addToOrder() {

        dispatch({type: 'ADD_HIGHLIGHTED', products: Object.keys(selection)});

        if (lid) {

            const lines = Object.values(selection).map(line => ({
                product: line.produkt_nr,
                amount: line.antall || 1
            }));

            dispatch(asyncAddToWishList(uid, lid, lines))
                .then(() => dispatch({type: 'SET_WISHLIST_DIRTY', wishlistDirty: true}));

        } else {

            const lines = Object.values(selection).map(line => ({
                produkt_nr: line.produkt_nr,
                amount: line.antall || 1
            }));

            dispatch(asyncOrderLines(orderId, uid, lines))
                .then(() => {
                    dispatch({type: 'CLEAR_SEARCH_SELECTION'});
                    dispatch({type: 'SET_ACTION', action: actions.NONE});
                    dispatch(setReduxQuery(''));
                });
        }

    }


    return (<Col md={7} sm={7} className="toolBoxLeft">
            <ButtonGroup>
                <Button color="info"
                        onClick={e => {
                            dispatch(setReduxQuery(''));
                            dispatch({type: 'SET_PANE', pane: sideViews.ORDER})
                        }}>
                    <Icon icon={chevronLeft}/>
                </Button>

                <WishlistsDropdown disabled={disabled} lines={Object.values(selection)}/>
                <LabelsDropdown disabled={disabled} selection={selection}/>

                <Button disabled={disabled || action === actions.ADD_TO_ORDER}
                        color="info"
                        onClick={addToOrder}>
                    <Icon icon={shoppingCart}/> Legg til
                </Button>
            </ButtonGroup>
        </Col>
    );
};

export const LabelsDropdown = ({selection, disabled}) => {

    const [labelDropdown, setLabelDropdown] = useState(false);

    const openLabels = (size, selection) => e => {
        e.preventDefault();

        const q = Object.keys(selection).map(c => encodeURIComponent(c)).join(',');
        window.open(`http://lakk.dna.argo.no/-@-users/generate?p=${q}&s=${size}`, '_blank');
    };

    return (
        <ButtonDropdown disabled={disabled} color="success" isOpen={labelDropdown}
                        toggle={e => setLabelDropdown(!labelDropdown)}>
            <DropdownToggle disabled={disabled} caret color="success">
                <Icon icon={maximize}/> Print
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={openLabels(89, selection)}>28x89 mm</DropdownItem>
                <DropdownItem onClick={openLabels(47, selection)}>28x47 mm</DropdownItem>
                <DropdownItem onClick={openLabels(57, selection)}>17x57 mm</DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>
    );

};

export const WishlistsDropdown = ({disabled, lines}) => {

    const user = useSelector(s => s.uid);
    const [dropdown, setDropdown] = useState(false);

    const [wishlists, wishlistsLoaded] = useSelector(s => [s.wishlists, s.wishlistsLoaded]);
    const [newWishListName, setNewWishListName] = useState('');

    const dispatch = useDispatch();
    const adding = useSelector(s => s.action === actions.WISHLIST_ADD);

    const add = list => () => {

        dispatch({type: 'SET_ACTION', action: actions.WISHLIST_ADD});
        const promise = Promise.all(lines.map(line => api.wishlistAdd(user, list.id, line.produkt_nr)));

        promise.then(() => {
            dispatch({type: 'SET_ACTION', action: actions.NONE});
            dispatch(asyncNotify('Product(s) has been added to list', 'success'));

            dispatch({type: 'SET_WISHLISTS_LOADED', wishlistsLoaded: false});
            dispatch(asyncWishLists(user));

            dispatch({type: 'CLEAR_ORDER_SELECTION'});
            dispatch({type: 'CLEAR_WISHLIST_SELECTION'});
            dispatch({type: 'CLEAR_SEARCH_SELECTION'});

            setDropdown(!dropdown);
            setNewWishListName('');

        });

        return promise;
    };

    const create = () => {
        api.wishlistCreate(user, newWishListName).then(({data: {wishlist}}) => {
            add(wishlist)();
        });
    };

    useEffect(() => {

        if (!wishlistsLoaded) {
            dispatch(asyncWishLists(user));
        }

    }, [wishlistsLoaded, dispatch, user]);


    return (
        <ButtonDropdown disabled={disabled || adding} color="primary" isOpen={dropdown}
                        toggle={e => setDropdown(!dropdown)}>
            <DropdownToggle disabled={disabled} caret color="primary">
                <Icon icon={tag}/> Liste
            </DropdownToggle>
            <DropdownMenu right>
                {wishlists.map(wishlist => (
                    <DropdownItem disabled={adding} key={wishlist.id} onClick={add(wishlist)}>
                        {wishlist.name}
                    </DropdownItem>
                ))}
                {/*<DropdownItem disabled={adding} key={-1}>*/}
                <InputGroup className={"dropdown-item"}>
                    <Input value={newWishListName}
                           placeholder={`Ny liste`}
                           onChange={e => setNewWishListName(e.target.value)}/>
                    <InputGroupAddon addonType={"append"}>
                        <Button onClick={create} color="success">
                            <Icon icon={save}/>
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
                {/*</DropdownItem>*/}
            </DropdownMenu>
        </ButtonDropdown>
    );
};

export const ToolboxOrder = () => {

    const dispatch = useDispatch();
    const selection = useSelector(s => s.orderSelection);
    const disabled = Object.keys(selection).length === 0;

    const action = useSelector(state => state.action);

    const [orderId, uid, pane] = useSelector(state => [state.orderId, state.uid, state.pane]);
    const [importer, setImporter] = useState(false);

    const size = pane === sideViews.ORDER ? 5 : 5;

    const importerFunc = data => {
        dispatch(asyncOrderLines(orderId, uid, data.map(produkt_nr => ({produkt_nr}))))
            .then(() => {
                dispatch(setReduxQuery(''));
                setImporter(false);
            });
    };


    return (
        <Col md={size} sm={size} className="toolBoxRight">
            <ToolboxCompanyWrapper className="float-right" component={(
                <>
                    <Button disabled={disabled || action === actions.DELETE_FROM_ORDER}
                            onClick={e => {
                                const lineIds = Object.values(selection).map(l => l.ordrelinje_nr);
                                dispatch(asyncOrderLinesDelete(orderId, uid, lineIds));
                            }} color="danger">
                        <Icon icon={trash2}/>
                    </Button>

                    <LabelsDropdown selection={selection} disabled={disabled}/>
                    <WishlistsDropdown disabled={disabled} lines={Object.values(selection)}/>
                    <FromWishList />
                    <Button onClick={() => setImporter(!importer)} color="warning">
                        <Icon icon={cpu}/>
                    </Button>
                    <ScannerModal modal={importer} setModal={setImporter} importerFunc={importerFunc}/>
                </>
            )}/>
        </Col>
    );
};

export const Toolbox = () => <BasicToolbox component={ToolboxOrder}/>;

export const BasicToolbox = ({component}) => {

    const pane = useSelector(s => s.pane);
    const Component = component;

    return (
        <div className="main-container container-fluid">
            <Row>
                {pane === sideViews.ORDER && <ToolboxInfo />}
                {pane === sideViews.SEARCH && <ToolboxSearch/>}
                {pane === sideViews.WISHLIST && <ToolboxWishList/>}
                <Component/>
            </Row>
            <Notification/>
        </div>
    );
};

