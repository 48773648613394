import React, {useState, useRef} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {Button, ButtonGroup, Col, Input, Row} from "reactstrap";
import {Icon} from "react-icons-kit";
import {checkSquare, check, x, chevronDown, chevronUp, send, wind, packageIcon} from "react-icons-kit/feather";
import {asyncOrderApprove, asyncOrderDelete, asyncOrderUpdate} from "./reducers/loginActions";
import {useRouter} from "./hooks/routerHooks";
import {emEvent} from "./api";
import Nf from "./NF";

export function ButtonWithConfirmation({handle, children, ...rest}) {

    const [c, sc] = useState(false);

    return (
        <ButtonGroup>
            {c || <Button color="danger" onClick={() => sc(true)} {...rest}>
                <Icon icon={send}/> {children}
            </Button>
            }
            {
                c &&
                <>
                    <Button color="danger" onClick={() => {
                        handle();
                        sc(false);
                    }}>
                        <Icon icon={check}/> Ja
                    </Button>
                    <Button color="success" onClick={() => sc(false)}>
                        <Icon icon={x}/> Avbryt
                    </Button>
                </>
            }
        </ButtonGroup>
    );

}

export function OrderFooter() {

    const [expanded, setExpanded] = useState(false);
    const [approving, setApproving] = useState(false);
    const [order, lines] = useSelector(s => [s.orderInfo, s.order]);
    const dispatch = useDispatch();
    const lm = require('./reducers/leveringsMaater').leveringsMaater;
    const canQuick = lines.length < 7 && lines.length > 0;

    const timeout = useRef(null);

    const {history} = useRouter();

    const handleChange = e => {

        const {target: {name, value}} = e;
        order[name] = value;

        dispatch({type: 'SET_ORDER_INFO', order: {...order}});

        if (timeout.current) clearTimeout(timeout.current);

        timeout.current = setTimeout(() => {
            dispatch(asyncOrderUpdate(order.ordre_nr, order.kontakt_nr, name, value))

        }, 1000);
    };

    const handleApprove = () => {

        setApproving(true);

        dispatch(asyncOrderApprove(order.ordre_nr, order.kontakt_nr, order))
            .then(() => history.push(`/user/${order.kontakt_nr}`))
            .finally(() => setApproving(false));
    };


    const handleDelete = () => {
        dispatch(asyncOrderDelete(order.ordre_nr, order.kontakt_nr)).then(() => {
            history.push(`/user/${order.kontakt_nr}`);
        })
    };


    return (
        <footer className={`footer ${expanded ? 'expanded' : ''}`}>
            <div className="footerContainer clearfix">
                <div className="footerToggle">
                    <Button onClick={e => setExpanded(!expanded)}>
                        <Icon size={25} icon={expanded ? chevronDown : chevronUp}/>
                    </Button>
                </div>
                {/* NOT EXPANDED */}
                {expanded || <Row className="orderFooterInfo">
                    <Col md={6} className="orderFooterSum">
                        Ordersum kr. <strong><Nf number={order.sum_total}/></strong>
                    </Col>
                    <Col md={6} className="text-right">
                        <Button color="warning" onClick={e => setExpanded(!expanded)}>
                            <Icon icon={checkSquare}/> Fullfør ordre
                        </Button>
                    </Col>
                </Row>}


                {/* EXPANDED */}
                {expanded &&
                <>
                    <Row className="orderFooterFields">
                        <Col md={4}>
                            <Input placeholder="Rekvisisjonnr./PO"
                                   name="DATA_ALPH_4"
                                   onChange={handleChange}
                                   value={order.DATA_ALPH_4 || ''}/>
                        </Col>
                        <Col md={4}>
                            <Input placeholder="Deres Ref"
                                   name="best_alt_levering_kommentar"
                                   onChange={handleChange}
                                   value={order.best_alt_levering_kommentar || ''}/>
                        </Col>
                        <Col md={4} className="text-right">
                            <Input type="select"
                                   name="DATA_NUM_2"
                                   onChange={handleChange} value={order.DATA_NUM_2 || ''}>
                                <option>Velg leveringsmåte</option>
                                {Object.keys(lm).map(k => <option key={k} value={k}>{lm[k]}</option>)}
                            </Input>
                        </Col>
                    </Row>
                    <Row className="orderFooterAction">
                        <Col md={12} className="orderFooterSum marginBottom text-center">
                            <div>Ordersum kr. <strong><Nf number={order.sum_total}/></strong></div>
                        </Col>
                        <Col md={4}>
                            <ButtonWithConfirmation handle={handleDelete}>Slett ordre</ButtonWithConfirmation>
                        </Col>
                        <Col md={8} className="orderFooterButtons text-right">
                            <ButtonGroup>
                                {canQuick && (
                                    <Button color="primary"
                                            className={"urgentBtn"}
                                            onClick={() => handleChange(emEvent('QO', order.QO === '1' ? '0' : '1'))}
                                            outline={order.QO !== '1'}>
                                        <Icon icon={wind}/>{` Haster`}
                                    </Button>
                                )}
                                <Button 
                                        className={"offerBtn"}
                                        color="warning"
                                        onClick={() => handleChange(emEvent('OrdTp', order.OrdTp === '5' ? '1' : '5'))}
                                        outline={order.OrdTp !== '5'}>
                                <Icon icon={packageIcon}/>{` Tilbud`}
                                </Button>

                                <Button
                                    disabled={lines.length < 1 || approving}
                                    color="success"
                                    onClick={handleApprove}
                                    className={"confirmBtn"}
                                >
                                    <Icon icon={send}/> Godkjenn ordre
                                </Button>
                            </ButtonGroup>
                            
                        </Col>
                    </Row>

                </>
                }
            </div>
        </footer>
    );

}