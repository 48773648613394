import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {asyncUserComments, asyncUserFav, asyncUserFetch, asyncWishLists} from "./reducers/loginActions";
import {Badge, Button, ButtonGroup, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {Icon} from "react-icons-kit";
import {chevronDown, chevronUp, edit2, home, save, tag} from "react-icons-kit/feather";

import {starOutline, starFullOutline} from 'react-icons-kit/typicons'

import {shortDepartments, departments} from "./api/warehouses";
import {actions, sideViews} from "./reducers/configureStore";
import {useLocation, useParams} from "./hooks/routerHooks";
import ButtonDropdown from "reactstrap/es/ButtonDropdown";

export const FavoriteButton = ({isFav, user}) => {

    const dispatch = useDispatch();
    const handleFavorite = () => {
        dispatch(asyncUserFav(user, !isFav));
    };

    return (
        <Button color="link" onClick={handleFavorite}>
            <Icon icon={isFav ? starFullOutline : starOutline}/>
        </Button>
    );
};

export const FromWishList = () => {

    const [wishlists, wishlistsLoaded] = useSelector(s => [s.wishlists, s.wishlistsLoaded]);
    const [dropdown, setDropdown] = useState(false);
    const {uid, oid} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {

        if (!wishlistsLoaded) {
            dispatch(asyncWishLists(uid));
        }

    }, [wishlistsLoaded, uid, dispatch]);



    const handleWishList = list => e => {

        dispatch({type: 'SET_WISHLIST', wishlist: list});
        dispatch({type: 'SET_PANE', pane: sideViews.WISHLIST});
    };

    return (

        <ButtonGroup>
            {(oid && wishlists.length > 0) && <ButtonDropdown isOpen={dropdown}
                                                              toggle={e => setDropdown(!dropdown)}>
                <DropdownToggle caret color="info">
                    <Icon icon={tag}/> Fra
                </DropdownToggle>
                <DropdownMenu>
                    {wishlists.map(list => (
                        <DropdownItem key={list.id} onClick={handleWishList(list)}>
                            {list.name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </ButtonDropdown>
            }
        </ButtonGroup>
    );
};

export const ToolboxInfo = ({md, sm, xl, xs}) => {

    const [info, action] = useSelector(state => [state.user, state.action]);
    const {uid} = useParams();
    const [{comment, editing}, setEditing] = useState({comment: '', editing: false});
    const [expanded, setExpanded] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(asyncUserFetch(uid)).then(() => {
            setEditing({editing, comment: info.internalComment});
        });

    }, [uid, dispatch]);

    const width = md || 7;
    const widthSm = sm || 7;
    const widthXl = xl || 7;
    const widthXs = xs || 7;

    const handleCommentSave = () => {
        dispatch(asyncUserComments(uid, comment))
            .finally(() => setEditing({editing: false, comment}));
    };

    return (

        <Col md={width} sm={widthSm} xl={widthXl} xs={widthXs} className="toolBoxLeft">
            <Row noGutters>

                <Col md={1}>
                    <Link to={`/user/${info.kontakt_nr}`} className="btn btn-info">
                        <Icon icon={home}/>
                    </Link>
                </Col>

                <Col md={11} className={`companyInfo ${action === actions.USER_LOAD && 'loading'}`}>
                    <ToolboxInfoView expanded={expanded}
                                     setExpanded={setExpanded}
                                     info={info}/>

                </Col>
{/*
                <Col md={2}>
                    <FromWishList />
                </Col>
*/}
            </Row>
            {expanded &&
            <Row className="companyInfoExpand">

                <Col md={6}>
                    <strong>Besøksadresse:</strong><br/>
                    {info.besoks_adresse}<br/>{info.postnr} {info.poststed}
                </Col>
                <Col md={6}>
                    <strong>Leveringsadresse:</strong><br/>
                    {info.levering_adresse &&
                    <div>{info.levering_adresse}</div>}{info.levering_postnr} {info.levering_poststed}
                </Col>
                <Col md={6}>
                    <strong>Kontaktperson:</strong><br/>
                    {info.kontaktperson_fornavn && <div>{info.kontaktperson_fornavn}</div>}
                    {info.kontaktperson_etternavn && <div>{info.kontaktperson_etternavn}</div>}
                    {info.telefon_1 && <div>Tlf: {info.telefon_1}</div>}
                    {info.telefon_2 && <div>Mob: {info.telefon_2}</div>}
                    {info.epost && <div><a href={`mailto:${info.epost}`}>{info.epost}</a></div>}
                </Col>
                <Col md={6}>
                    <strong>Lager:<br/></strong>
                    {departments[info.DATA_NUM_9]}
                    <div>

                        <strong>Kommentar:<br/></strong>
                        {editing && (
                            <div>
                                <Input type="textarea" value={comment}
                                       onChange={e => setEditing({editing, comment: e.target.value})}/>
                                <Button color="success" onClick={handleCommentSave}><Icon icon={save}/></Button>
                            </div>
                        )}
                        {editing || (
                            <div>
                                {info.internalComment}
                                <Button color="link"
                                        onClick={() => setEditing({editing: !editing, comment: info.internalComment})}>
                                    <Icon icon={edit2}/>
                                </Button>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
            }
        </Col>
    );
};

function ToolboxInfoView({info, expanded, setExpanded}) {

    const {oid, uid} = useSelector(s => s.currentOrderEdited);
    const {navigate} = useLocation();

    return (
        <>
            <FavoriteButton isFav={info.isFav} user={info.kontakt_nr}/>
            <Button color="link" className="companyName" onClick={() => setExpanded(!expanded)}>
                <strong>{info.firmanavn}</strong>
                <Icon icon={expanded ? chevronUp : chevronDown}/>
            </Button>
            <span className="companyId">
                #{info.kontakt_nr}
                {info.besoks_adresse ? ' / ' + info.besoks_adresse  : ''}
                {' / '}
                {shortDepartments[info.DATA_NUM_9]}
                {oid && (
                    <Badge pill className="mono" color="success" style={{fontSize: '0.7rem'}}
                           onClick={() => navigate(`/orders/${uid}/edit/${oid}`)}>{oid}</Badge>
                )}

            </span>

            {/*{expanded && <div>Lager {departments[info.DATA_NUM_10]} : {info.besoks_adresse}<br />{info.postnr} {info.poststed}</div>}*/}
        </>
    );
}


