export const departments = {
    '0': '',
    '1': 'Fyllingsdalen (Lager 1)',
    '2': 'Trondheim',
    '3': 'Fyllingsdalen (Lager 3 –Mekonomen)',
    '4': 'Åsane',
    '5': 'Sandnes',
    '6': 'Haugesund'
};

export const shortDepartments = {
    '0': '',
    '1': 'Fyl 1',
    '2': 'Trnd',
    '3': 'Mek 3',
    '4': 'Åsane',
    '5': 'Sand',
    '6': 'Hsnd'
};

export const warehouses = {
    '1': ['Bergen', 'produkt_felt_int_2'],
    '3': ['Bergen', 'produkt_felt_int_3'],
    '2': ['Trondheim', 'produkt_felt_int_5'],
    '4': ['Bergen', 'produkt_felt_int_6'],
    '5': ['Sandnes', 'produkt_felt_int_7'],
    '6': ['Haugesund', 'produkt_felt_int_8'],
};